import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { auth, db } from "./firebase";
import SearchBar from "./SearchBar";
import CreatableSelect from "react-select/creatable";
import LocationsList from "./LocationsList";
import {
  collection,
  query,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

async function fetchLocationsData() {
  const q = query(collection(db, "locations"), orderBy("name"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

async function fetchRolesData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const rolesByHourSet = new Set();
  const rolesByUnitSet = new Set();
  locationsSnapshot.forEach((doc) => {
    const rolesMap = doc.data().roles;
    if (rolesMap) {
      Object.keys(rolesMap).forEach((role) => {
        if (rolesMap[role] === "notByHour") {
          rolesByUnitSet.add(role);
        } else {
          rolesByHourSet.add(role);
        }
      });
    }
  });
  const fetchedRolesByHour = Array.from(rolesByHourSet).map((role) => ({
    value: role,
    label: role,
  }));
  const fetchedRolesByUnit = Array.from(rolesByUnitSet).map((role) => ({
    value: role,
    label: role,
  }));
  return {
    byHour: fetchedRolesByHour,
    byUnit: fetchedRolesByUnit,
  };
}

async function saveLocationData({ locationData, editingLocationId }) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No authenticated user available");
  }

  if (editingLocationId) {
    await updateDoc(doc(db, "locations", editingLocationId), locationData);
  } else {
    await addDoc(collection(db, "locations"), locationData);
  }
}

function Locations({ isGoogleMapsLoaded }) {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addressInputRef = useRef(null);
  const [locationForm, setLocationForm] = useState({
    name: "",
    address: "",
    supervisors: [],
    radius: 1000,
    latitude: null,
    longitude: null,
    rolesByHour: [],
    rolesByUnit: [],
  });
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: locations,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocationsData,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRolesData,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const saveLocationMutation = useMutation({
    mutationFn: saveLocationData,
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
      closeModal();
    },
    onError: (error) => {
      console.error("Error saving location: ", error);
    },
  });

  useEffect(() => {
    if (isModalOpen && isGoogleMapsLoaded && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          setLocationForm((prevState) => ({
            ...prevState,
            address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          }));
        }
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [isGoogleMapsLoaded, isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocationForm((prevState) => ({
      ...prevState,
      [name]: name === "radius" ? Number(value) : value,
    }));
  };

  const handleAddLocation = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLocationForm({
      name: "",
      address: "",
      supervisors: [],
      radius: 1000,
      latitude: null,
      longitude: null,
      rolesByHour: [],
      rolesByUnit: [],
    });
    setEditingLocationId(null);
    if (addressInputRef.current) {
      addressInputRef.current.value = "";
    }
  };

  const handleSaveLocation = () => {
    const rolesMap = locationForm.rolesByHour.reduce((acc, current) => {
      acc[current.value] = "";
      return acc;
    }, {});

    locationForm.rolesByUnit.forEach((current) => {
      rolesMap[current.value] = "notByHour";
    });

    const locationData = {
      ...locationForm,
      radius: Number(locationForm.radius),
      roles: rolesMap,
      lastModifiedBy: auth.currentUser.uid,
      lastModifiedAt: Timestamp.now(),
    };

    saveLocationMutation.mutate({ locationData, editingLocationId });
  };

  const handleEditLocation = (location) => {
    const rolesByHour = [];
    const rolesByUnit = [];
    Object.entries(location.roles || {}).forEach(([role, value]) => {
      if (value === "notByHour") {
        rolesByUnit.push({ value: role, label: role });
      } else {
        rolesByHour.push({ value: role, label: role });
      }
    });

    setLocationForm({
      name: location.name || "",
      address: location.address || "",
      latitude: location.latitude || null,
      longitude: location.longitude || null,
      radius: location.radius || 1000,
      rolesByHour,
      rolesByUnit,
    });
    setEditingLocationId(location.id);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border shadow-lg rounded-md bg-white w-11/12 max-w-2xl z-50">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Add/Edit Location
              </h3>
              <input
                type="text"
                name="name"
                value={locationForm.name}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                id="location-name"
                placeholder="Enter new location name"
              />
              <input
                type="text"
                ref={addressInputRef}
                name="address"
                value={locationForm.address}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                id="location-address"
                placeholder="Enter new location address"
              />

              <label
                htmlFor="rolesByHour"
                className="block text-sm font-medium text-gray-700 mt-2"
              >
                Positions By Hour
              </label>
              <CreatableSelect
                isMulti
                name="rolesByHour"
                options={roles?.byHour}
                value={locationForm.rolesByHour}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setLocationForm((prevState) => ({
                    ...prevState,
                    rolesByHour: selectedOptions,
                  }))
                }
                onCreateOption={(inputValue) => {
                  const newOption = { value: inputValue, label: inputValue };
                  queryClient.setQueryData(["roles"], (oldRoles) => ({
                    ...oldRoles,
                    byHour: [...oldRoles.byHour, newOption],
                  }));
                  setLocationForm((prevState) => ({
                    ...prevState,
                    rolesByHour: [...prevState.rolesByHour, newOption],
                  }));
                }}
              />
              <label
                htmlFor="rolesByUnit"
                className="block text-sm font-medium text-gray-700 mt-2"
              >
                Positions By Unit
              </label>
              <CreatableSelect
                isMulti
                name="rolesByUnit"
                options={roles?.byUnit}
                value={locationForm.rolesByUnit}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setLocationForm((prevState) => ({
                    ...prevState,
                    rolesByUnit: selectedOptions,
                  }))
                }
                onCreateOption={(inputValue) => {
                  const newOption = { value: inputValue, label: inputValue };
                  queryClient.setQueryData(["roles"], (oldRoles) => ({
                    ...oldRoles,
                    byUnit: [...oldRoles.byUnit, newOption],
                  }));
                  setLocationForm((prevState) => ({
                    ...prevState,
                    rolesByUnit: [...prevState.rolesByUnit, newOption],
                  }));
                }}
              />
              <label
                htmlFor="radius"
                className="block text-sm font-medium text-gray-700 mt-2"
              >
                Radius (meters)
              </label>
              <input
                type="number"
                name="radius"
                value={locationForm.radius}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                id="radius"
                placeholder="1000"
              />
              <div className="mt-5 flex justify-between px-10">
                <button
                  onClick={closeModal}
                  className="text-white bg-red-500 hover:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                >
                  Close
                </button>
                <button
                  onClick={handleSaveLocation}
                  className="text-white bg-green-500 hover:bg-green-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-lg font-bold text-white uppercase tracking-wider">
        Locations
      </h1>
      <SearchBar
        onSearch={(term) => {
          setSearchTerm(term);
        }}
      />
      <LocationsList
        locations={filteredLocations}
        onEditLocation={handleEditLocation}
      />
      <button
        onClick={handleAddLocation}
        className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full shadow-lg flex items-center justify-center"
        style={{
          width: "56px",
          height: "56px",
          fontSize: "24px",
          borderRadius: "50%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
}

export default Locations;
