import React, { createContext, useContext, useReducer } from "react";

// Define the initial state
const initialState = {
  hasLicense: false,
  speaksEnglish: false,
  identificationType: "",
  open: false,
  firstName: "",
  middleName: "",
  lastName: "",
  city: "",
  state: "",
  zip: "",
  address: "", 
  error: null,
  availability: "",
  restrictions: "",
  email: "",
  emailError: null,
  emailSuggestion: null,
};

// Create the context
const FormContext = createContext();

// Define actions
const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "SET_ERROR":
      return { ...state, error: action.value };
    case "RESET_FORM":
      return initialState;
    default:
      return state;
  }
};

// Create a provider component
export const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const setField = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const setError = (value) => {
    dispatch({ type: "SET_ERROR", value });
  };

  const resetForm = () => {
    dispatch({ type: "RESET_FORM" });
  };

  return (
    <FormContext.Provider value={{ state, setField, setError, resetForm }}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook to use the FormContext
export const useFormContext = () => {
  return useContext(FormContext);
};
