import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

const UniquePunch = () => {
  const openAppLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const iOSLink = "https://apps.apple.com/us/app/unique-punch/id6463495209";
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.rickyalves.uniquePunch";

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(iOSLink, "_blank");
    } else if (/android/i.test(userAgent)) {
      window.open(androidLink, "_blank");
    } else {
      window.open(iOSLink, "_blank");
      window.open(androidLink, "_blank");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 p-4">
      <div className="flex flex-col items-center justify-center text-center p-6 bg-white/30 backdrop-blur-md border border-white/10 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 max-w-md w-full">
        <div className="text-white text-5xl md:text-6xl audiowide mb-4">
          UNIQUE
        </div>
        <div className="text-white text-5xl md:text-6xl audiowide">PUNCH</div>
        <p className="text-base md:text-lg mb-6 md:mb-10 text-gray-100">
          Effortlessly manage your time and attendance.
        </p>
        <button
          onClick={openAppLink}
          className="flex items-center justify-center gap-2 px-6 py-3 md:px-8 md:py-4 bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-1 hover:scale-110"
          aria-label="Download or Update App"
        >
          <FontAwesomeIcon icon={faApple} className="text-lg md:text-xl" />
          <FontAwesomeIcon icon={faGooglePlay} className="text-lg md:text-xl" />
          Download/Update
        </button>
      </div>
    </div>
  );
};

export default UniquePunch;
