import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { db } from "./firebase";

const Timesheets = memo(() => {
  const getLastWeekRange = () => {
    const lastWeekStart = format(
      startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    const lastWeekEnd = format(
      endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    return { lastWeekStart, lastWeekEnd };
  };

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState(getLastWeekRange().lastWeekStart);
  const [endDate, setEndDate] = useState(getLastWeekRange().lastWeekEnd);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [showApprovedOnly, setShowApprovedOnly] = useState(true);

  useEffect(() => {
    const { lastWeekStart, lastWeekEnd } = getLastWeekRange();
    setStartDate(lastWeekStart);
    setEndDate(lastWeekEnd);
  }, []);

  useEffect(() => {
    const calculateHours = (roleChanges, endTimestamp, breakTimestamps) => {
      const roleTimes = roleChanges.map((change) => ({
        role: change.role,
        timestamp:
          change.timestamp instanceof Date
            ? change.timestamp
            : change.timestamp.toDate(),
      }));

      const end =
        endTimestamp instanceof Date
          ? endTimestamp
          : endTimestamp?.toDate() ?? new Date();

      roleTimes.push({ role: null, timestamp: end });

      const roleDurations = {};

      for (let i = 0; i < roleTimes.length - 1; i++) {
        const { role, timestamp } = roleTimes[i];
        const nextTimestamp = roleTimes[i + 1].timestamp;

        let shiftDuration = (nextTimestamp - timestamp) / 1000;

        if (breakTimestamps && breakTimestamps.length > 1) {
          for (let j = 0; j < breakTimestamps.length; j += 2) {
            const breakStart =
              breakTimestamps[j] instanceof Date
                ? breakTimestamps[j]
                : breakTimestamps[j]?.toDate
                ? breakTimestamps[j].toDate()
                : new Date(breakTimestamps[j]);
            const breakEnd =
              (breakTimestamps[j + 1] instanceof Date
                ? breakTimestamps[j + 1]
                : breakTimestamps[j + 1]?.toDate
                ? breakTimestamps[j + 1].toDate()
                : new Date(breakTimestamps[j + 1])) ?? new Date();

            if (breakStart >= timestamp && breakEnd <= nextTimestamp) {
              shiftDuration -= (breakEnd - breakStart) / 1000;
            }
          }
        }

        const shiftHours = shiftDuration / 3600;
        roleDurations[role] = (roleDurations[role] || 0) + shiftHours;
      }

      return roleDurations;
    };

    const fetchShifts = async () => {
      const start = new Date(`${startDate}T00:00:00`);
      const end = new Date(`${endDate}T23:59:59`);

      const queryConditions = [
        collection(db, "shifts"),
      ];

      if (showApprovedOnly) {
        queryConditions.push(where("approvedBy", "!=", null));
      }

      if (selectedLocation && selectedLocation !== "All Locations") {
        queryConditions.push(where("locationName", "==", selectedLocation));
      }

      const shiftsQuery = query(...queryConditions);
      const querySnapshot = await getDocs(shiftsQuery);

      const shiftsData = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const roleChanges = data.roleChanges || [];
        const shiftStart = data.startTimestamp.toDate();
        const shiftEnd = data.endTimestamp ? data.endTimestamp.toDate() : null;
        const breaks = data.breakTimestamps || [];

        const shiftWithinDateRange =
          (shiftStart >= start && shiftStart <= end) ||
          (shiftEnd >= start && shiftEnd <= end);

        if (shiftWithinDateRange) {
          const employeeKey = data.userId || "missing-userId";
          if (!shiftsData[employeeKey]) {
            shiftsData[employeeKey] = {
              userId: employeeKey,
              firstName: data.firstName || "Unknown",
              lastName: data.lastName || "Unknown",
              positions: new Set(),
              locations: new Set([data.locationName]),
              method: new Set([data.payMethod]),
              rates: new Set([parseFloat(data.rate) || 0]),
              dailyHours: {},
            };
          } else {
            shiftsData[employeeKey].locations.add(data.locationName);
            shiftsData[employeeKey].method.add(data.payMethod);
            shiftsData[employeeKey].rates.add(parseFloat(data.rate) || 0);
          }

          const roleDurations = calculateHours(roleChanges, shiftEnd, breaks);
          const dayKey = format(shiftStart, "yyyy-MM-dd");

          if (!shiftsData[employeeKey].dailyHours[dayKey]) {
            shiftsData[employeeKey].dailyHours[dayKey] = {};
          }

          Object.entries(roleDurations).forEach(([role, hours]) => {
            shiftsData[employeeKey].positions.add(role);
            shiftsData[employeeKey].dailyHours[dayKey][role] =
              (shiftsData[employeeKey].dailyHours[dayKey][role] || 0) + hours;
          });
        }
      });

      const aggregatedShiftsData = Object.values(shiftsData)
        .filter((employee) => employee.positions.size > 0)
        .map((employee) => ({
          ...employee,
          positions: Array.from(employee.positions).join(", "),
          locations: Array.from(employee.locations).join(", "),
          method: Array.from(employee.method),
          rates: Array.from(employee.rates),
        }));

      setEmployees(aggregatedShiftsData);
    };

    fetchShifts().catch(console.error);
  }, [startDate, endDate, selectedLocation, selectedPosition, showApprovedOnly]);

  useEffect(() => {
    const fetchLocationsAndPositions = async () => {
      const shiftsQuery = query(collection(db, "shifts"));
      const querySnapshot = await getDocs(shiftsQuery);

      const newLocations = [];
      const newPositions = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { locationName, roleChanges } = data;

        roleChanges.forEach(({ role }) => {
          if (locationName && !newLocations.includes(locationName)) {
            newLocations.push(locationName);
          }

          if (role && !newPositions.includes(role)) {
            newPositions.push(role);
          }
        });
      });

      if (
        newLocations.sort().toString() !== locations.sort().toString() ||
        newPositions.sort().toString() !== positions.sort().toString()
      ) {
        setLocations(newLocations);
        setPositions(newPositions);
      }
    };

    fetchLocationsAndPositions().catch(console.error);
  }, [locations, positions]);

  const convertHoursToHM = (hours) => {
    const totalMinutes = Math.floor(hours * 60);
    const hoursPart = Math.floor(totalMinutes / 60);
    const minutesPart = totalMinutes % 60;
    return `${hoursPart.toString().padStart(2, "0")}:${minutesPart
      .toString()
      .padStart(2, "0")}`;
  };

  const convertHoursToHMS = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const hoursPart = Math.floor(totalSeconds / 3600);
    const minutesPart = Math.floor((totalSeconds % 3600) / 60);
    const secondsPart = totalSeconds % 60;
    return `${hoursPart}:${minutesPart
      .toString()
      .padStart(2, "0")}:${secondsPart.toString().padStart(2, "0")}`;
  };

  const parseHMToDecimal = (hms) => {
    if (typeof hms === "string") {
      const [hours, minutes, seconds] = hms.split(":").map(Number);
      return hours + minutes / 60 + (seconds || 0) / 3600;
    }
    return 0;
  };

  const sumHM = (hoursArray) => {
    return hoursArray.reduce((acc, cur) => {
      if (cur === "Off" || cur === "0:00:00") return acc;
      return acc + parseHMToDecimal(cur);
    }, 0);
  };

  const renderTimesheets = () => {
    const groups = groupEmployeesByLocationAndPosition(employees);

    const isAboveTenHours = (hours) => {
      return parseHMToDecimal(hours) > 10;
    };

    const isTotalAboveFortyHours = (hours) => {
      return parseHMToDecimal(hours) > 40;
    };

    const isNegativeHours = (hours) => {
      return parseHMToDecimal(hours) < 0;
    };

    return (
      <div className="p-8 bg-[#1F2937] min-h-screen text-white">
        <h1 className="text-2xl font-bold mb-6">Timesheets</h1>
        <div className="flex items-center justify-between w-full mx-auto bg-[#d6e7ff] p-4 rounded-lg shadow-md mb-4 text-black">
          <div className="flex items-center space-x-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-32 text-sm"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-32 text-sm"
            />
          </div>

          <div className="flex items-center space-x-2">
            <div className="relative">
              <button
                id="dropdownDefaultButton"
                className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-xs px-3 py-2 text-center inline-flex items-center"
                type="button"
                onClick={toggleLocationDropdown}
              >
                {selectedLocation || "Location"}
                <svg className="ml-1 w-3 h-3" fill="none" viewBox="0 0 20 20">
                  <path
                    d="M5.5 7L10 11.5L14.5 7"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {locationDropdownOpen && (
                <div
                  id="dropdown"
                  className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute mt-1 overflow-auto max-h-60"
                >
                  <ul
                    className="py-1 text-sm text-gray-700"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          setSelectedLocation("All Locations");
                          setLocationDropdownOpen(false);
                        }}
                      >
                        All Locations
                      </button>
                    </li>
                    {locations.map((location) => (
                      <li key={location}>
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                          onClick={() => {
                            setSelectedLocation(location);
                            setLocationDropdownOpen(false);
                          }}
                        >
                          {location}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="relative">
              <button
                id="dropdownPositionButton"
                className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-xs px-3 py-2 text-center inline-flex items-center"
                type="button"
                onClick={() => setPositionDropdownOpen(!positionDropdownOpen)}
              >
                {selectedPosition || "Position"}
                <svg className="ml-1 w-3 h-3" fill="none" viewBox="0 0 20 20">
                  <path
                    d="M5.5 7L10 11.5L14.5 7"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {positionDropdownOpen && (
                <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute">
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          setSelectedPosition("All Positions");
                          setPositionDropdownOpen(false);
                        }}
                      >
                        All Positions
                      </button>
                    </li>
                    {positions.map((position) => (
                      <li key={position}>
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                          onClick={() => {
                            setSelectedPosition(position);
                            setPositionDropdownOpen(false);
                          }}
                        >
                          {position}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <label className="flex items-center space-x-1 bg-white px-2 py-1 rounded-lg">
              <input
                type="checkbox"
                checked={showApprovedOnly}
                onChange={(e) => setShowApprovedOnly(e.target.checked)}
                className="form-checkbox h-4 w-4 text-blue-600"
              />
              <span className="text-xs font-medium text-gray-700">Approved</span>
            </label>
          </div>

          <button
            onClick={exportToExcel}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded flex items-center text-xs"
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-1" /> Export
          </button>
        </div>
        {groups.map((group, index) => {
          const { location, position, employees: groupEmployees } = group;
          const dateRange = eachDayOfInterval({
            start: parseISO(startDate),
            end: parseISO(endDate),
          });

          const positionTotalHours = groupEmployees.reduce((total, emp) => {
            const dailyHoursArray = dateRange.map((date) => {
              const dateKey = format(date, "yyyy-MM-dd");
              return emp.dailyHours[dateKey]
                ? convertHoursToHMS(emp.dailyHours[dateKey])
                : "0:00:00";
            });
            return total + sumHM(dailyHoursArray);
          }, 0);

          return (
            <div key={`${location}-${position}-${index}`}>
              <h2 className="text-xl font-bold mb-4">
                {location} ({position})
              </h2>
              <table className="min-w-full bg-white rounded-lg mb-6">
                <thead className="bg-gray-200 text-gray-700">
                  <tr>
                    <th className="px-4 py-2">Employee</th>
                    {dateRange.map((date) => (
                      <th
                        key={format(date, "yyyy-MM-dd")}
                        className="px-4 py-2"
                      >
                        {format(date, "EEEE MMM d")}
                      </th>
                    ))}
                    <th className="px-4 py-2">Total</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {groupEmployees.map((employee, empIndex) => (
                    <tr key={employee.userId}>
                      <td
                        className={`border px-4 py-2 ${
                          empIndex === 0 ? "rounded-tl-lg" : ""
                        }`}
                      >
                        {employee.firstName} {employee.lastName}
                      </td>
                      {dateRange.map((date) => {
                        const dateKey = format(date, "yyyy-MM-dd");
                        const hours = employee.dailyHours[dateKey]
                          ? convertHoursToHMS(employee.dailyHours[dateKey])
                          : "Off";
                        return (
                          <td
                            key={dateKey}
                            className={`border px-4 py-2 ${
                              isAboveTenHours(hours)
                                ? "bg-red-500 text-white"
                                : isNegativeHours(hours)
                                ? "bg-purple-500 text-white"
                                : ""
                            }`}
                          >
                            {hours !== "Off" ? hours : "Off"}
                          </td>
                        );
                      })}
                      <td
                        className={`border px-4 py-2 ${
                          empIndex === groupEmployees.length - 1
                            ? "rounded-br-lg"
                            : ""
                        } ${
                          isTotalAboveFortyHours(
                            convertHoursToHMS(
                              sumHM(
                                dateRange.map((date) => {
                                  const dateKey = format(date, "yyyy-MM-dd");
                                  return employee.dailyHours[dateKey]
                                    ? convertHoursToHMS(
                                        employee.dailyHours[dateKey]
                                      )
                                    : "0:00:00";
                                })
                              )
                            )
                          )
                            ? "bg-red-500 text-white"
                            : ""
                        }`}
                      >
                        {convertHoursToHMS(
                          sumHM(
                            dateRange.map((date) => {
                              const dateKey = format(date, "yyyy-MM-dd");
                              return employee.dailyHours[dateKey]
                                ? convertHoursToHMS(
                                    employee.dailyHours[dateKey]
                                  )
                                : "0:00:00";
                            })
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th colSpan={dateRange.length}>
                    {convertHoursToHMS(positionTotalHours)}
                  </th>
                </tr>
              </tfoot>
            </div>
          );
        })}
      </div>
    );
  };

  const groupEmployeesByLocationAndPosition = (employees) => {
    const grouped = {};
    employees.forEach((employee) => {
      employee.positions.split(", ").forEach((position) => {
        employee.locations.split(", ").forEach((location) => {
          const key = `${location} - ${position}`;

          if (!grouped[key]) {
            grouped[key] = {
              location: location,
              position: position,
              employees: [],
            };
          }

          const newEmployeeEntry = {
            ...employee,
            dailyHours: { ...employee.dailyHours },
            positions: position,
          };

          Object.keys(newEmployeeEntry.dailyHours).forEach((date) => {
            if (!newEmployeeEntry.dailyHours[date][position]) {
              delete newEmployeeEntry.dailyHours[date];
            } else {
              newEmployeeEntry.dailyHours[date] =
                newEmployeeEntry.dailyHours[date][position];
            }
          });

          grouped[key].employees.push(newEmployeeEntry);
        });
      });
    });
    return Object.values(grouped);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    const groupedPositions = groupEmployeesByLocationAndPosition(employees);

    const dateRange = eachDayOfInterval({
      start: parseISO(startDate),
      end: parseISO(endDate),
    });
    const dateRangeHeaders = dateRange.map((date) =>
      format(date, "EEEE MMM d")
    );

    groupedPositions.forEach((group) => {
      wsData.push([`${group.location} (${group.position})`]);

      wsData.push(["Employee", ...dateRangeHeaders, "Total"]);

      const groupData = group.employees.map((emp) => {
        const hoursData = dateRange.map((date) => {
          const dateKey = format(date, "yyyy-MM-dd");
          return emp.dailyHours[dateKey]
            ? convertHoursToHM(emp.dailyHours[dateKey])
            : "Off";
        });

        const totalHours = hoursData.reduce((total, current) => {
          return current !== "Off"
            ? total + parseHoursToDecimal(current)
            : total;
        }, 0);

        return [
          `${emp.firstName} ${emp.lastName}`,
          ...hoursData,
          convertHoursToHM(totalHours),
        ];
      });

      wsData.push(...groupData);

      const positionTotalDecimalHours = groupData.reduce((total, row) => {
        return total + parseHoursToDecimal(row[row.length - 1]);
      }, 0);

      wsData.push([
        "Total",
        ...Array(dateRangeHeaders.length).fill(""),
        convertHoursToHM(positionTotalDecimalHours),
      ]);

      wsData.push([]);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

    const filename = `export_${new Date()
      .toISOString()
      .replace(/[\W_]+/g, "_")}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const parseHoursToDecimal = (hhmm) => {
    if (typeof hhmm === "string" && hhmm !== "Off") {
      const [hours, minutes] = hhmm.split(":");
      return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
    }
    return 0;
  };

  const toggleLocationDropdown = () => {
    setLocationDropdownOpen(!locationDropdownOpen);
    setPositionDropdownOpen(false);
  };

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-center mb-6"></div>
      {renderTimesheets()}
    </div>
  );
});

export default Timesheets;
