import React, { useState, useEffect } from "react";
import "./tailwind.css";
import { db, serverTimestamp } from "./firebase";
import { addDoc, collection } from "firebase/firestore";
import { FormProvider, useFormContext } from "./FormContext";
import useEmailValidation from "./useEmailValidation";
import classNames from "classnames";
import { STATES } from "./constants";
import { useNavigate } from "react-router-dom";

const ApplicationForm = () => {
  const { state, setField, setError } = useFormContext();
  const { emailError, emailSuggestion, validateEmail } = useEmailValidation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "email",
      "firstName",
      "lastName",
      "address",
      "city",
      "state",
      "zip",
      "availability",
      "identificationType",
    ];

    requiredFields.forEach((field) => {
      if (!state[field] || state[field].trim() === "") {
        errors[field] = "This field is required";
      }
    });

    // Special handling for phone number
    const phoneNumber = document.getElementById("phone-number").value.trim();
    if (!phoneNumber) {
      errors.phone = "This field is required";
    }

    // Special handling for birth date
    const birthDate = document.getElementById("birthDate").value.trim();
    if (!birthDate) {
      errors.birthDate = "This field is required";
    }

    // Special case for jobType as it's a radio button
    if (!state.jobType) {
      errors.jobType = "Please select a job type";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) {
      // Scroll to the first error
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    setIsSubmitting(true);

    const rawPhoneNumber = document.getElementById("phone-number").value.trim();
    const sanitizedPhoneNumber = rawPhoneNumber.replace(/-/g, "");

    const applicationData = {
      phone: sanitizedPhoneNumber,
      email: state.email.trim(),
      firstName: state.firstName.trim(),
      middleName: state.middleName.trim(),
      lastName: state.lastName.trim(),
      birthDate: document.getElementById("birthDate").value.trim(),
      address: state.address.trim(),
      city: state.city.trim(),
      state: state.state.trim(),
      zip: state.zip.trim(),
      jobType: state.jobType,
      availability: state.availability.trim(),
      restrictions: state.restrictions.trim(),
      hasLicense: state.hasLicense,
      speaksEnglish: state.speaksEnglish,
      identificationType: state.identificationType,
      englishProficiencyLevel: state.speaksEnglish
        ? state.englishProficiencyLevel
        : null,
      timestamp: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, "applications"), applicationData);
      setError(null);
      setIsModalOpen(true);
    } catch (error) {
      setError(error.message);
      setIsModalOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function capitalizeName(name) {
    if (!name) return name;
    const lowercasedName = name.toLowerCase();
    const exceptions = ["de", "da", "das", "do", "dos"];
    return lowercasedName
      .split(" ")
      .map((word) =>
        exceptions.includes(word)
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }

  function capitalizeSentences(str) {
    if (!str) return str;
    str = str.charAt(0).toUpperCase() + str.slice(1);
    const regex = /\.(\s*)([a-z])/g;
    return str.replace(regex, (match, spaces, char) => {
      return "." + spaces + char.toUpperCase();
    });
  }

  function handleTextAreaChange(event) {
    const capitalizedText = capitalizeSentences(event.target.value);
    setField(event.target.name, capitalizedText);
  }

  const handleInputChange = (event) => {
    const capitalized = capitalizeName(event.target.value);
    setField(event.target.name, capitalized);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length <= 3) {
      e.target.value = value;
    } else if (value.length <= 6) {
      e.target.value = value.slice(0, 3) + "-" + value.slice(3);
    } else {
      e.target.value =
        value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10);
    }
    // Update the state with the formatted phone number
    setField("phone", e.target.value);
  };

  const handleBirthDateChange = (e) => {
    setField("birthDate", e.target.value);
  };

  const handleZipChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 5);
    setField("zip", value);
  };

  const validateZip = (zip) => {
    return /^\d{5}$/.test(zip);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (!state.error) {
      navigate("/login");
    }
  };

  const handleEnglishProficiencyChange = (e) => {
    setField("englishProficiencyLevel", e.target.value);
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="p-8 bg-[#1F2937] min-h-screen text-white"
    >
      <h2 className="text-2xl font-bold mb-6 audiowide text-center">
        Employee Application Form
      </h2>
      <div className="w-full max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Personal Information
        </h3>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="phone-number"
            >
              Phone Number
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.phone ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="phone-number"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              required
              value={state.phone}
              onChange={handlePhoneChange}
            />
            {formErrors.phone && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.phone}
              </p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.email ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="email"
              placeholder="ella.fitzgerald@gmail.com"
              type="email"
              required
              value={state.email}
              onChange={(e) => {
                const lowerCaseEmail = e.target.value.toLowerCase();
                setField("email", lowerCaseEmail);
                validateEmail(lowerCaseEmail, setField);
              }}
            />
            {emailError && (
              <p className="text-red-500 text-xs italic">{emailError}</p>
            )}
            {emailSuggestion && (
              <p
                className="text-blue-500 text-xs italic cursor-pointer"
                onClick={() => setField("email", emailSuggestion)}
              >
                Did you mean: {emailSuggestion}?
              </p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
              htmlFor="grid-first-name"
            >
              First Name
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.firstName ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
              id="grid-first-name"
              type="text"
              placeholder="Ella"
              value={state.firstName}
              onChange={handleInputChange}
              name="firstName"
            />
            {formErrors.firstName && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.firstName}
              </p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-middle-name"
            >
              Middle Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-middle-name"
              type="text"
              placeholder="Mae"
              value={state.middleName}
              onChange={handleInputChange}
              name="middleName"
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Last Name
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.lastName ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
              id="grid-last-name"
              type="text"
              placeholder="Fitzgerald"
              value={state.lastName}
              onChange={handleInputChange}
              name="lastName"
            />
            {formErrors.lastName && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.lastName}
              </p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="birthDate"
            >
              Birth Date
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.birthDate ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="birthDate"
              type="date"
              required
              value={state.birthDate || ""}
              onChange={handleBirthDateChange}
            />
            {formErrors.birthDate && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.birthDate}
              </p>
            )}
          </div>
        </div>
        <hr className="my-6 border-t border-gray-300" />
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Contact Information
        </h3>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-address"
            >
              Address
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.address ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="address"
              type="text"
              name="address"
              placeholder="Enter your address"
              value={state.address}
              onChange={(e) => setField("address", e.target.value)}
              required
            />
            {formErrors.address && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.address}
              </p>
            )}
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
              htmlFor="grid-city"
            >
              City
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.city ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-city"
              type="text"
              value={state.city}
              onChange={(e) => setField("city", e.target.value)}
              required
            />
            {formErrors.city && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.city}
              </p>
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
              htmlFor="grid-state"
            >
              State
            </label>
            <select
              className={`block appearance-none w-full bg-gray-200 border ${
                formErrors.state ? "border-red-500" : "border-gray-200"
              } text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-state"
              value={state.state}
              onChange={(e) => setField("state", e.target.value)}
              required
            >
              <option value=""></option>
              {STATES.map(({ name, abbreviation }) => (
                <option key={abbreviation} value={abbreviation}>
                  {name}
                </option>
              ))}
            </select>
            {formErrors.state && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.state}
              </p>
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
              htmlFor="grid-zip"
            >
              Zip Code
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                formErrors.zip ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-zip"
              type="text"
              inputMode="numeric"
              pattern="\d{5}"
              maxLength="5"
              value={state.zip}
              onChange={handleZipChange}
              onBlur={() => {
                if (!validateZip(state.zip)) {
                  setFormErrors({
                    ...formErrors,
                    zip: "Please enter a valid 5-digit zip code",
                  });
                } else {
                  setFormErrors({ ...formErrors, zip: null });
                }
              }}
              required
            />
            {formErrors.zip && (
              <p className="text-red-500 text-xs italic error-message">
                {formErrors.zip}
              </p>
            )}
          </div>
        </div>
        <hr className="my-6 border-t border-gray-300" />
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Job Type
        </h3>
        <fieldset>
          <div className="mt-6 space-y-6">
            <div className="flex items-center gap-x-3">
              <input
                id="full-time"
                name="job_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="full_time"
                checked={state.jobType === "full_time"}
                onChange={() => setField("jobType", "full_time")}
                required
              />
              <label
                htmlFor="full-time"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Full Time
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="part-time"
                name="job_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="part_time"
                checked={state.jobType === "part_time"}
                onChange={() => setField("jobType", "part_time")}
                required
              />
              <label
                htmlFor="part-time"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Part Time
              </label>
            </div>
          </div>
          {formErrors.jobType && (
            <p className="text-red-500 text-xs italic error-message">
              {formErrors.jobType}
            </p>
          )}
        </fieldset>
        <hr className="my-6 border-t border-gray-300" />
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Job Details
        </h3>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="availability"
            >
              Availability
            </label>
            <textarea
              id="availability"
              name="availability"
              rows="4"
              value={state.availability}
              onChange={handleTextAreaChange}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Example: Prefer morning shifts on weekdays and full days on weekends."
            ></textarea>
          </div>
          <div className="w-full px-3 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="restrictions"
            >
              Restrictions
            </label>
            <textarea
              id="restrictions"
              name="restrictions"
              rows="4"
              value={state.restrictions}
              onChange={handleTextAreaChange}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Example: Cannot work on Tuesdays and Thursdays. No heavy lifting due to back issues."
            ></textarea>
          </div>
        </div>
        <hr className="my-6 border-t border-gray-300" />
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Identification Type
        </h3>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <div className="flex-grow text-sm font-medium text-gray-900">
              Do you have a driver's license?
            </div>
            <div className="flex-shrink-0">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={state.hasLicense}
                  onChange={() => setField("hasLicense", !state.hasLicense)}
                />
                <div
                  className={classNames(
                    "w-11 h-6 rounded-full transition-colors duration-200 ease-in-out",
                    state.hasLicense ? "bg-blue-600" : "bg-red-200"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      "block h-5 w-5 rounded-full bg-white shadow-sm transform transition-transform duration-200 ease-in-out",
                      state.hasLicense ? "translate-x-5" : "translate-x-0.5"
                    )}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-grow text-sm font-medium text-gray-900">
              Do you speak English?
            </div>
            <div className="flex-shrink-0">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={state.speaksEnglish}
                  onChange={() =>
                    setField("speaksEnglish", !state.speaksEnglish)
                  }
                />
                <div
                  className={classNames(
                    "w-11 h-6 rounded-full transition-colors duration-200 ease-in-out",
                    state.speaksEnglish ? "bg-blue-600" : "bg-red-200"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      "block h-5 w-5 rounded-full bg-white shadow-sm transform transition-transform duration-200 ease-in-out",
                      state.speaksEnglish ? "translate-x-5" : "translate-x-0.5"
                    )}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        {state.speaksEnglish && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              English Proficiency Level
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              onChange={handleEnglishProficiencyChange}
              value={state.englishProficiencyLevel || ""}
            >
              <option value="">Select proficiency level</option>
              <option value="basic">Basic</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
              <option value="native">Native</option>
            </select>
          </div>
        )}
        <fieldset>
          <div className="mt-6 space-y-6">
            <div className="flex items-center gap-x-3">
              <input
                id="ssn"
                name="identification_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="ssn"
                checked={state.identificationType === "ssn"}
                onChange={() => setField("identificationType", "ssn")}
                required
              />
              <label
                htmlFor="ssn"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Social Security Number (SSN)
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="ein"
                name="identification_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="ein"
                checked={state.identificationType === "ein"}
                onChange={() => setField("identificationType", "ein")}
                required
              />
              <label
                htmlFor="ein"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Employer Identification Number (EIN)
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="itin"
                name="identification_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="itin"
                checked={state.identificationType === "itin"}
                onChange={() => setField("identificationType", "itin")}
                required
              />
              <label
                htmlFor="itin"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Individual Taxpayer Identification Number (ITIN)
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="none"
                name="identification_type"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                value="none"
                checked={state.identificationType === "none"}
                onChange={() => setField("identificationType", "none")}
                required
              />
              <label
                htmlFor="none"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                None
              </label>
            </div>
          </div>
          {formErrors.identificationType && (
            <p className="text-red-500 text-xs italic error-message">
              {formErrors.identificationType}
            </p>
          )}
        </fieldset>
        <div className="flex">
          <button
            type="submit"
            className="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full modal-overlay">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {state.error
                    ? "Error Submitting Application"
                    : "Application Submitted"}
                </h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">
                    {state.error
                      ? `${state.error}. Please try again later.`
                      : "Your application was sent successfully. We'll get in touch."}
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={handleModalClose}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

const ApplicationFormWrapper = () => (
  <FormProvider>
    <ApplicationForm />
  </FormProvider>
);

export default ApplicationFormWrapper;
