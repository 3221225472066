import React, { useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

// Fetching function to get applications
const fetchApplications = async () => {
  const applicationsRef = collection(db, "applications");
  const applicationsSnapshot = await getDocs(applicationsRef);
  let states = new Set();
  let applicationsData = applicationsSnapshot.docs.map((doc) => {
    const data = doc.data();
    states.add(data.state);
    return { ...data, id: doc.id };
  });

  return { applicationsData, states: Array.from(states).sort() };
};

function ApplicationReview() {
  const [selectedState, setSelectedState] = useState("");

  // Use useQuery to fetch applications and states
  const { data, isLoading, error } = useQuery({
    queryKey: ["applications"],
    queryFn: fetchApplications,
  });

  const handleStateChange = useCallback((e) => {
    setSelectedState(e.target.value);
  }, []);

  const filteredApplications = useMemo(() => {
    if (!data) return [];
    return data.applicationsData.filter(
      (application) =>
        selectedState === "" || application.state === selectedState
    );
  }, [data, selectedState]);

  const sortedApplications = useMemo(() => {
    return filteredApplications.sort((a, b) => {
      const dateA = a.timestamp.toDate();
      const dateB = b.timestamp.toDate();
      return dateB - dateA;
    });
  }, [filteredApplications]);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="bg-[#1F2937] p-8 rounded-lg shadow-md">
      <div className="filter-container mb-4 mt-4">
        <select
          onChange={handleStateChange}
          value={selectedState}
          className="w-full px-5 py-3 border-b-2 rounded-lg border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
        >
          <option value="">Select a State</option>
          {data.states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <table className="w-full bg-white rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Name
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Submitted On
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              City/State
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Actions
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Interview
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedApplications.map((application) => {
            console.log(
              "Processing application for:",
              application.firstName,
              application.lastName
            );

            let phone = application.phone;
            let isValidPhoneNumber = true;
            console.log("Original phone number:", phone);

            if (typeof phone !== "string") {
              phone = String(phone);
            }
            console.log("Phone number after conversion to string:", phone);

            phone = phone.replace(/-/g, "");
            console.log("Phone number after removing hyphens:", phone);

            if (phone && phone.length === 10 && !isNaN(phone)) {
              console.log("US format detected for:", phone);
              phone = "1" + phone;
            } else if (
              phone &&
              phone.length === 11 &&
              phone.startsWith("1") &&
              !isNaN(phone)
            ) {
              console.log('US format with leading "1" detected for:', phone);
            } else if (
              phone &&
              phone.length === 11 &&
              !phone.startsWith("55") &&
              !isNaN(phone)
            ) {
              console.log('Brazilian format without "55" detected for:', phone);
              phone = "55" + phone;
            } else if (
              phone &&
              phone.length === 13 &&
              phone.startsWith("55") &&
              !isNaN(phone)
            ) {
              console.log('Brazilian format with "55" detected for:', phone);
            } else {
              console.log("Invalid or unrecognized format for:", phone);
              isValidPhoneNumber = false;
            }

            let formattedDate = application.timestamp
              .toDate()
              .toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZoneName: "short",
              });

            return (
              <tr
                key={application.id}
                className={`transition-all ${
                  application.isInterviewPassed
                    ? "bg-green-100 hover:bg-green-200"
                    : "hover:bg-gray-100"
                }`}
              >
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  <div className="font-medium text-lg">
                    {application.firstName} {application.middleName}{" "}
                    {application.lastName}
                  </div>
                  <div className="mt-2 flex space-x-4">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log(
                          "WhatsApp button clicked. Phone number:",
                          phone,
                          "Valid:",
                          isValidPhoneNumber
                        );
                        if (isValidPhoneNumber) {
                          window.open(`https://wa.me/${phone}`, "_blank");
                        }
                      }}
                      className={`flex items-center justify-center font-bold w-36 py-2 ${
                        isValidPhoneNumber ? "bg-[#27D366]" : "bg-gray-300"
                      } text-white rounded hover:opacity-90`}
                      title="WhatsApp"
                      disabled={!isValidPhoneNumber}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 mr-4"
                        fill="currentColor"
                        style={{ color: "#128c7e" }}
                        viewBox="0 0 24 24"
                      >
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                      </svg>
                      WhatsApp
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = `mailto:${application.email}`;
                      }}
                      className="flex items-center justify-center font-bold w-36 py-2 bg-gray-500 text-white rounded hover:opacity-90"
                      title="Email"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="h-7 w-7 mr-6"
                      />
                      Email
                    </button>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  {formattedDate}
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  <div className="font-small text-lg">
                    {application.city}, {application.state}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm relative">
                  <Link
                    to={`/interview/${application.id}`}
                    className="px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700"
                    title="Start Interview"
                  >
                    Details
                  </Link>
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  {application.isInterviewPassed ? (
                    <div className="flex items-center justify-center text-green-500">
                      <FontAwesomeIcon icon={faCheck} className="h-6 w-6" />
                      <span className="ml-2 font-bold">Passed Interview</span>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center text-black-800">
                      <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
                      <span className="ml-2 font-bold">Interview Pending</span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ApplicationReview;
