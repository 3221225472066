import React, { useState } from "react";
import { auth, db } from "./firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./tailwind.css";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Login({ user }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password === "unique1234") {
      try {
        await sendPasswordResetEmail(auth, email);
        setError(
          "Since you are attempting to log in with the default password, we have sent you an email to guide you through changing it to ensure your account's security."
        );
      } catch (sendPasswordResetError) {
        console.error("Password reset error:", sendPasswordResetError);
        setError(
          "There was an issue sending the password reset email. Please try again later."
        );
      } finally {
        setLoading(false);
      }
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log(userData.status);
        if (userData.status === "terminated") {
          alert(
            "Your account is not active. Please check your status with your supervisor."
          );
          await auth.signOut();
          setLoading(false);
          return;
        }

        if (
          userData.hasSignedNonCompete === false ||
          userData.hasSignedNonCompete === undefined
        ) {
          navigate("/non-compete-agreement");
        } else {
          const role = userData.role;
          const applicationComplete = userData.applicationComplete;

          if (
            role === "owner" ||
            role === "manager" ||
            role === "admin" ||
            role === "director" ||
            role === "supervisor"
          ) {
            navigate("/dashboard");
          } else if (role === "employee" || role === "mobile") {
            if (applicationComplete === false) {
              navigate(`/complete-application/${user.uid}`);
            } else {
              throw new Error(
                "Looks like you already completed your application. We're getting in touch with you soon."
              );
            }
          } else {
            setError(
              "You are ready to start working with us. Please contact your supervisor."
            );
          }
        }
      } else {
        throw new Error("User data not found. Please contact your supervisor.");
      }
    } catch (error) {
      console.error("Login error:", error);
      const errorCode = error.message.match(/\(([^)]+)\)/)[1];
      const friendlyMessage = getFriendlyErrorMessage(errorCode);
      setError(friendlyMessage);
    } finally {
      setLoading(false);
    }
  };

  const getFriendlyErrorMessage = (errorCode) => {
    const errorMessages = {
      "auth/wrong-password":
        "The password you entered is incorrect. Please try again.",
      "auth/user-not-found":
        "No account found with this email. Please check your email and try again.",
      "auth/invalid-login-credentials":
        "Invalid login credentials. Please check your email and password then try again.",
      "auth/too-many-requests":
        "Too many failed attempts. Please wait a few minutes before trying again.",
      "auth/account-disabled":
        "Your account has been disabled. Please contact your supervisor for assistance.",
    };
    return (
      errorMessages[errorCode] ||
      "An unexpected error occurred. Please try again later."
    );
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-6 sm:p-12 md:p-24 bg-black">
      <div className="relative flex flex-col items-center justify-center mb-8 sm:mb-12 md:mb-16">
        <div className="relative z-10 text-white text-6xl sm:text-7xl md:text-8xl audiowide mb-2 sm:mb-3 md:mb-4" style={{
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)'
        }}>
          UNIQUE
        </div>
        <div className="relative z-10 text-white text-6xl sm:text-7xl md:text-8xl audiowide" style={{
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)'
        }}>
          HUB
        </div>
        {/* Brighter blue glow effect */}
        <div style={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          right: '-50%',
          bottom: '-50%',
          background: 'radial-gradient(circle, rgba(59, 130, 246, 0.3) 0%, rgba(59, 130, 246, 0.2) 40%, transparent 70%)',
          filter: 'blur(40px)',
          zIndex: 5,
        }}></div>
        {/* Enhanced shine layer */}
        <div style={{
          position: 'absolute',
          top: '-25%',
          left: '-25%',
          right: '-25%',
          bottom: '-25%',
          background: 'conic-gradient(from 180deg at 50% 50%, #38bdf8 0deg, #3b82f6 180deg, transparent 360deg)',
          opacity: 0.2,
          filter: 'blur(60px)',
          zIndex: 6,
        }}></div>
      </div>
      <div className="flex flex-col items-center mb-16 sm:mb-24 md:mb-32">
        {error && (
          <div
            className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-4"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-teal-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">Attention</p>
                <p className="text-sm">{error}</p>
              </div>
            </div>
          </div>
        )}
        <form
          className="shadow-md rounded px-4 sm:px-6 md:px-8 pt-6 pb-8 mb-4 w-full max-w-xs sm:max-w-sm md:max-w-md"
          onSubmit={handleLogin}
        >
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Sign In"}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 focus:outline-none focus:shadow-outline"
              onClick={() => navigate("/apply")}
            >
              New Employee? Apply Now
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default Login;
