import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth } from "./firebase";

function NonCompeteAgreement() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [todayDate, setTodayDate] = useState(new Date());

  useEffect(() => {
    setTodayDate(new Date());
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.error("No such document!");
        }
      }
    };

    fetchUserData();
  }, []);

  const handleAgree = async () => {
    setLoading(true);
    setError(null);
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const currentDate = new Date();

      try {
        await updateDoc(userDocRef, {
          hasSignedNonCompete: true,
          nonCompeteSignedDate: currentDate,
        });
        const updatedUserDoc = await getDoc(userDocRef);

        if (updatedUserDoc.exists()) {
          const userData = updatedUserDoc.data();
          const role = userData.role;

          if (
            ["owner", "manager", "admin", "director", "supervisor"].includes(
              role
            )
          ) {
            navigate("/dashboard");
          } else if (role === "employee") {
            alert(
              "Thank you for signing the agreement. You will now be logged out."
            );
            await auth.signOut();
            navigate("/login");
          } else {
            console.error("Unexpected role or no role defined.");
          }
        } else {
          console.error("Unable to fetch updated user data.");
        }
      } catch (error) {
        console.error(
          "Error updating document or handling post-agreement process:",
          error
        );
        setError(
          "An error occurred while processing your agreement. Please try again."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 p-6 shadow-lg">
      <div className="max-w-4xl mx-auto py-8 flex-grow rounded-xl bg-white shadow-2xl p-6">
        <div className="p-6 rounded-xl bg-gradient-to-r from-white to-gray-100 shadow-inner">
          <h1 className="text-2xl font-bold mb-4 text-gray-800 drop-shadow">
            Non-Compete Agreement
          </h1>
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            1. Purpose
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            This Non-Compete Agreement ("Agreement"), dated{" "}
            <strong>{todayDate.toLocaleDateString()}</strong>, is entered into
            by Unique On The Go Corp, with a mailing address of 617 Summer St,
            Lynn, Massachusetts, 01905 ("Owner"), and{" "}
            {userData ? (
              <strong className="text-gray-800 drop-shadow">
                {`${userData.firstName} ${
                  userData.middleName ? userData.middleName + " " : ""
                }${userData.lastName}`}
              </strong>
            ) : (
              <strong className="text-gray-800 drop-shadow">
                [Recipient's Full Name]
              </strong>
            )}
            , with a mailing address of{" "}
            {userData ? (
              <strong className="text-gray-800 drop-shadow">
                {`${userData.address}, ${userData.city}, ${userData.state}, ${userData.zip}`}
              </strong>
            ) : (
              <strong className="text-gray-800 drop-shadow">
                [Recipient's Address], [City], [State], [Zip Code]
              </strong>
            )}{" "}
            ("Recipient"). This Agreement prohibits the Recipient from
            disclosing certain confidential and proprietary information and
            trade secrets relating to the business of the Owner and restricts
            the Recipient's employment opportunities post-termination with
            specific competitors within the defined territories.
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Recipient shall strictly maintain the confidentiality of the
            Proprietary Information. Proprietary information may be shared
            between the Parties for use in scoping, estimating, and completing
            projects as well as for the everyday business practices for the
            Owner and its clients/customers.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            2. Non-Compete/Disclosure
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            During the term of this Agreement and for 6 months thereafter, the
            Recipient shall not:
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            -Engage in or provide services to competitors of the Owner,
            including but not limited to Hertz, Sixt Rental Car, Avis, Budget,
            Payless, TCR, and any other related company offering services to
            rental car companies. Hire, work with, or partner with any current
            or former employees or sales staff of the Owner for any business
            activities without the Owner's written consent.
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            -Directly or indirectly engage in any similar business practice of
            the Owner while being in contact with the Owner's current or former
            clients. Nor shall the Recipient solicit any client of the Owner for
            the benefit of a third party that is engaged in a similar business
            to that of the Owner.
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            -Engage in business activity, whether paid or non-paid, with the
            following business(es) or individual(s): Hertz, Dollar & Thrifty
            rental car. Sixt Rental Car Avis, Budget and Payless MLS EDS And any
            other related company that provides services to rental car
            companies.
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            -Hire, work alongside, or partner with any current employees, sales
            staff, or former employees or sales staff of Owner.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            3. Time Period
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            The non-compete obligation begins on the date of hiring and extends
            for 6 months following the termination of the Recipient's employment
            with the Owner.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            4. Geographical Scope and Limits
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            The geographical scope includes all states and territories within
            the United States of America, collectively known as the "Restricted
            Territory."
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            The Recipient shall not conduct any competitive activity prohibited
            by this Agreement within the Restricted Territory.
          </p>

          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            5. Purchase Option
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            The Owner does not allow the Recipient to be released of liability
            from this Agreement for any monetary amount or reason whatsoever.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            6. Confidential Information
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            As used herein "Confidential Information" shall mean any and all
            technical and non-technical information provided by the Owner,
            including but not limited to: data or other proprietary information
            relating to products, inventions, plans, methods, processes,
            know-how, developmental or experimental work, computer programs,
            databases, authorship, customer lists (including the names, buying
            habits or practices of any clients), the names of vendors or
            suppliers, marketing methods, reports, analyses, business plans,
            financial information, statistical information, or any other subject
            matter pertaining to any business of the Owner or any it's
            respective clients, consultants, or licensees that is disclosed to
            the Recipient under the terms of this Agreement.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            7. Permitted Disclosure
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Confidential Information does not include information which:
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Has become generally known to the public through no wrongful act by
            the Recipient;
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Has been rightfully received by Recipient from a third party without
            restriction on disclosure and without breach of an obligation of
            confidentiality running either directly or indirectly to the
            Recipient;
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Has been approved for release to the general public by written
            authorization of the Owner;
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Has been disclosed pursuant to the requirement of a governmental
            agency or a court of law without similar restrictions or other
            protections against public disclosure; or,
          </p>
          <p className="mb-4 text-gray-600 drop-shadow">
            Has been independently developed by the Recipient without use,
            directly or indirectly of the Owner's Confidential Information.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            8. Confidentiality
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Recipient acknowledges that it will have access to the Owner's
            Confidential Information and agrees that it shall not directly or
            indirectly divulge, disclose or communicate any of the Confidential
            Information to any third party, except as may be required in the
            course of any formal business association or dealings with the Owner
            and in any event, only with the prior written approval of the Owner.
            The Recipient acknowledges that no license of the Confidential
            Information, by implication or otherwise, is granted to the
            Recipient by reason of this Agreement. Additionally, the Recipient
            acknowledges that it may only use the Confidential Information in
            connection with its business dealings with the Owner and for no
            other purpose without the prior written consent of the Owner. The
            Recipient further agrees that all Confidential Information,
            including without limitation any documents, files, reports,
            notebooks, samples, lists, correspondence, software, or other
            written or graphic records provided by the Owner or produced using
            the Owner's Confidential Information, will be held strictly
            confidential and returned upon request to the Owner. The term of
            this Agreement will be ongoing as long as the Parties are working
            together in any formal capacity. The conditions of this Agreement
            shall survive the termination of this Agreement.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            9. Consultants and Employees Bound
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Recipient agrees to disclose the Confidential Information to any
            agents, affiliates, directors, officers or any other employees
            (collectively, the "Employees") solely on a need-to-know basis and
            represents that such Employees have signed appropriate non-compete
            agreements or taken appropriate measures imposing on such Employees
            a duty to third parties (1) to hold any third party proprietary
            information received by such Employees in the strictest confidence,
            (2) not to disclose such third party Confidential Information to any
            other third party, and (3) not to use such Confidential Information
            for the benefit of anyone other than to whom it belongs, without the
            prior express written authorization of the Owner.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            10. Return of Materials
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Upon termination or expiration of the Agreement, or upon written
            request of the Owner, the Recipient shall promptly return to the
            Owner all documents and other tangible materials representing the
            Owner's Confidential Information and all copies thereof. The Owner
            shall notify immediately the Recipient upon discovery of any loss or
            unauthorized disclosure of the Confidential Information.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            11. Remedies
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Should the Recipient breach any of the provisions of this Agreement
            by unauthorized use, or by disclosure of the Confidential
            Information to any unauthorized third party to the Owner's detriment
            or damage, the Recipient agrees to reimburse the Owner for any loss
            or expense incurred by the Owner as a result of such use or
            unauthorized disclosure or attempted disclosure, including without
            limitation court costs and reasonable attorney's fees incurred by
            the Owner in enforcing the provisions hereof. Recipient further
            agrees that any unauthorized use of or disclosure of the
            Confidential Information will result in irreparable damage to the
            Owner and that the Owner shall be entitled to an award by any court
            of competent jurisdiction of a temporary restraining order and/or
            preliminary injunction against such unauthorized use or disclosure
            by the Recipient without the need to post a bond. Such remedies,
            however, shall not be deemed to be the exclusive remedies for any
            breach of this Agreement but shall be in addition to all other
            remedies available at law or equity.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            12. Attorney Fees
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            If the Recipient breaches any of the restrictive covenant terms in
            this Agreement, to the extent authorized by state law, the Recipient
            will be responsible for payment of all reasonable attorneys' fees
            and costs the Company incurred in the course of enforcing the terms
            of the Agreement, including demonstrating the existence of a breach
            and any other contract enforcement efforts.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            13. Choice of Law and Forum Selection
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            This Agreement shall be governed by, and construed in accordance
            with, the laws of the State of Massachusetts, without giving effect
            to any conflict of law provisions that would require or permit the
            laws of any jurisdiction other than the State of Massachusetts to
            apply. Any action or proceeding by the Parties to enforce this
            Agreement shall be brought only in any state or federal court
            located in the state of Massachusetts. The Parties hereby
            irrevocably submit to the non-exclusive jurisdiction of such courts
            and waive the defense of inconvenient forum to the maintenance of
            any such action or proceeding in such venue. If any provisions of
            this Agreement or its applications is held to be invalid, illegal or
            unenforceable in any respect, the validity, legality or
            enforceability of any other provisions and applications herein shall
            not in any way be affected or impaired.
          </p>
          <br />
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            14. Employee Post-Termination Employment Restriction:
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            Following the termination of employment with the Owner, the
            Recipient agrees not to accept employment, engage in consultancy, or
            undertake any business activity with the Owner's clients, including
            but not limited to Hertz, Sixt Rental Car, Avis, Budget, Payless,
            TCR, and any other entities offering services to rental car
            companies, within the Restricted Territory for a period of 6 months.
            A breach of this clause will subject the Recipient to a fee of
            $3,500.00 per incident, payable to the Owner as liquidated damages.
          </p>
          <h3 className="text-xl font-bold mb-4 text-gray-700 drop-shadow">
            15. Entire Agreement
          </h3>
          <p className="mb-4 text-gray-600 drop-shadow">
            This Agreement represents the entire agreement of the parties and
            may only be modified by signature by both parties hereto.
          </p>
          <p className="text-center mt-8 text-gray-600 drop-shadow">
            Date of Agreement: <strong>{todayDate.toLocaleDateString()}</strong>
          </p>
        </div>

        {/* Button at the bottom */}
        <div className="flex justify-center pb-8">
          <button
            className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-700 hover:to-blue-500 text-white font-bold py-2 px-4 rounded-full shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-transform"
            onClick={handleAgree}
            disabled={loading}
            aria-busy={loading}
          >
            {loading ? "Processing..." : "I Agree"}
          </button>
        </div>
        {error && <div className="text-red-500 text-center">{error}</div>}
      </div>
    </div>
  );
}

export default NonCompeteAgreement;
