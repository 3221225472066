import React, { useState, useCallback, useEffect, useRef } from "react";
import { db } from "./firebase";
import EmployeeList from "./EmployeeList";
import SearchBar from "./SearchBar";
import index from "./algoliaClient";
import EmployeeModal from "./EmployeeModal";
import WarningModal from "./WarningModal";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faCheck,
  faExclamationTriangle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

function UserDirectory({ userStatus = "Active", userRole }) {
  const [employees, setEmployees] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sortAscending] = useState(true);
  const [sortField, setSortField] = useState("firstName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isEditing, setIsEditing] = useState(false);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const NOT_INITIALIZED = Symbol("NOT_INITIALIZED");
  const [lastVisible, setLastVisible] = useState(NOT_INITIALIZED);
  const prevCurrentPage = useRef(1);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const isNextDisabled = !hasMore || (employees && employees.length < 20);
  const [resultCount, setResultCount] = useState(0);

  // Function to fetch employee details from Firestore
  const fetchEmployeeDetails = async (uid) => {
    try {
      const employeeDocRef = doc(db, "users", uid);
      const docSnapshot = await getDoc(employeeDocRef);
      if (docSnapshot.exists()) {
        return { uid: docSnapshot.id, ...docSnapshot.data() };
      } else {
        console.error("No employee found with UID:", uid);
        return null;
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
      return null;
    }
  };

  const handleInputChange = (field, value) => {
    setSelectedEmployee((prev) => ({ ...prev, [field]: value }));
  };

  const updateSelectedEmployee = (updatedFields) => {
    setSelectedEmployee((prev) => ({ ...prev, ...updatedFields }));
  };

  const handleSave = async () => {
    if (!selectedEmployee || !selectedEmployee.uid) {
      console.error("No selected employee or missing UID");
      return;
    }

    setIsLoading(true);

    const employeeDocRef = doc(db, "users", selectedEmployee.uid);
    const { id, role, ...employeeData } = selectedEmployee;

    // Ensure role is a string before calling toLowerCase
    const roleLower = role ? role.toLowerCase() : "";

    try {
      console.log("Saving data to Firestore:", {
        ...employeeData,
        role: roleLower,
      });
      await updateDoc(employeeDocRef, { ...employeeData, role: roleLower });

      console.log("Data updated in Firestore");

      // Update Algolia with only searchable fields
      const algoliaData = {
        objectID: selectedEmployee.uid,
        firstName: employeeData.firstName,
        middleName: employeeData.middleName,
        lastName: employeeData.lastName,
        email: employeeData.email,
        phone: employeeData.phone,
        position: employeeData.position,
        workLocation: employeeData.workLocation,
        city: employeeData.city,
        state: employeeData.state,
        role: roleLower,
        status: employeeData.status,
      };
      await index.partialUpdateObject(algoliaData);
      console.log("Data updated in Algolia");

      setAlert({ type: "success", message: "Data saved successfully!" });
      setTimeout(() => setAlert({ type: "", message: "" }), 3000);
      setIsEditing(false);

      const docSnapshot = await getDoc(employeeDocRef);
      if (docSnapshot.exists()) {
        const updatedEmployee = { id: docSnapshot.id, ...docSnapshot.data() };
        console.log("Updated employee data:", updatedEmployee); // Log the updated employee data
        setSelectedEmployee(updatedEmployee);

        // Update employee in the employees list
        setEmployees((prevEmployees) => {
          return prevEmployees.map((emp) =>
            emp.id === updatedEmployee.id ? updatedEmployee : emp
          );
        });
      }
    } catch (error) {
      console.error("Error updating data: ", error);
    } finally {
      setCurrentPage(1);
      setIsLoading(false);
    }
  };

  const handleOpenModal = async (employee) => {
    const detailedEmployee = await fetchEmployeeDetails(employee.objectID);
    if (detailedEmployee) {
      setSelectedEmployee(detailedEmployee);
      setIsModalOpen(true);
    }
  };

  <EmployeeModal
    selectedEmployee={selectedEmployee}
    updateSelectedEmployee={updateSelectedEmployee}
  />;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedEmployee(null);
    setCurrentPage(1);
  };

  const sortedEmployees = [...employees].sort((a, b) => {
    if (sortAscending) {
      return a.firstName.localeCompare(b.firstName);
    } else {
      return b.firstName.localeCompare(a.firstName);
    }
  });

  const getResultText = (count) => {
    if (count === 0) {
      return "No results found";
    } else if (count === 1) {
      return "1 result";
    } else {
      return `${count} results`;
    }
  };

  const loadMoreEmployees = useCallback(
    async (searchTerm = lastSearchTerm) => {
      setIsLoading(true);
      try {
        const response = await index.search(searchTerm, {
          page: currentPage - 1,
          hitsPerPage: 20,
          filters: `status:"${userStatus}"`,
        });

        const { hits, page, nbPages, nbHits } = response;
        setEmployees(hits);
        setHasMore(page + 1 < nbPages);
        setResultCount(nbHits);
      } catch (error) {
        console.error("Error loading employees:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [lastSearchTerm, currentPage, userStatus]
  );

  const handleSortToggle = (field) => {
    if (sortField === field) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
    setEmployees([]);
    setLastVisible(null);
    setHasMore(true);
    loadMoreEmployees();
  };

  useEffect(() => {
    // Only fetch employees if it's the initial load or if the page number changes
    if (
      lastVisible === NOT_INITIALIZED ||
      currentPage !== prevCurrentPage.current
    ) {
      loadMoreEmployees();
    }
    prevCurrentPage.current = currentPage;
  }, [loadMoreEmployees, currentPage, lastVisible, NOT_INITIALIZED]);

  // Function to determine the warning icon and color
  const getWarningIconData = (warnings) => {
    let warningIcon;
    let warningColor;
    const warningsCount = warnings?.length || 0;

    switch (warningsCount) {
      case 0:
        warningIcon = faCheck;
        warningColor = "#28a745";
        break;
      case 1:
        warningIcon = faExclamationTriangle;
        warningColor = "#ffc107";
        break;
      default: // 2 or more warnings lead to suspension
        warningIcon = faTimesCircle;
        warningColor = "#dc3545";
    }

    return { warningIcon, warningColor };
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleWarningClick = (employee) => {
    setCurrentEmployee(employee);
    setIsWarningModalOpen(true);
  };

  const handleSaveWarning = async (date, text) => {
    if (!currentEmployee || !currentEmployee.uid) {
      console.error("No selected employee or missing ID");
      return;
    }

    // Construct the new warning object
    const trimmedText = text.trim();
    const newWarning = { date, text: trimmedText };

    // Get a reference to the employee document in Firestore
    const employeeDocRef = doc(db, "users", currentEmployee.uid);

    try {
      // Fetch the current document to make sure we have the latest warnings array
      const docSnapshot = await getDoc(employeeDocRef);

      if (docSnapshot.exists()) {
        // Get the current warnings or default to an empty array if it doesn't exist
        const currentWarnings = docSnapshot.data().warnings || [];

        // Add the new warning to the array
        const updatedWarnings = [...currentWarnings, newWarning];

        // Update the document with the new array of warnings
        await setDoc(
          employeeDocRef,
          { warnings: updatedWarnings },
          { merge: true }
        );

        // Update local state if necessary, e.g., to show the updated list of warnings
        setEmployees((prevEmployees) =>
          prevEmployees.map((emp) =>
            emp.id === currentEmployee.id
              ? { ...emp, warnings: updatedWarnings }
              : emp
          )
        );

        console.log("Warning saved successfully!");
      } else {
        console.error("No such employee document!");
      }
    } catch (error) {
      console.error("Error saving warning: ", error);
    } finally {
      // Close the modal
      setIsWarningModalOpen(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold text-gray-200 uppercase tracking-wider">
          Staff Directory
        </h1>
      </div>
      {alert.message && (
        <div
          className={`border-t-4 px-4 py-3 shadow-md mb-4 flex ${
            alert.type === "success"
              ? "bg-teal-100 border-teal-500 text-teal-900"
              : "bg-red-100 border-red-500 text-red-900"
          }`}
          role="alert"
        >
          <div className="py-1 mr-4">
            <svg
              class="fill-current h-6 w-6 text-teal-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">{alert.message}</p>
          </div>
        </div>
      )}
      <SearchBar
        onSearch={(term) => {
          setLastSearchTerm(term);
          setEmployees([]);
          setCurrentPage(1);
          loadMoreEmployees(term);
        }}
      />
      {/* Result Counter */}
      <div className="mb-4 text-white font-semibold text-grey-600 uppercase tracking-wider">
        {getResultText(resultCount)}
      </div>{" "}
      <EmployeeList
        employees={sortedEmployees}
        onRowClick={handleOpenModal}
        sortField={sortField}
        sortDirection={sortDirection}
        handleSortToggle={handleSortToggle}
        userRole={userRole}
        getWarningIconData={getWarningIconData}
        onWarningClick={handleWarningClick}
      />
      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        onSave={handleSaveWarning}
        initialDate={getTodayDate()}
        employeeId={currentEmployee?.id}
        existingWarnings={currentEmployee?.warnings || []}
      />
      {isModalOpen && (
        <EmployeeModal
          selectedEmployee={selectedEmployee}
          isEditing={isEditing}
          handleCloseModal={handleCloseModal}
          handleSave={handleSave}
          handleInputChange={handleInputChange}
          setIsEditing={setIsEditing}
          isLoading={isLoading}
          alert={alert}
          setAlert={setAlert}
          updateSelectedEmployee={updateSelectedEmployee}
          userRole={userRole}
        />
      )}
      <div
        className="flex justify-between mt-4"
        style={{ maxWidth: "300px", margin: "0 auto" }}
      >
        <div className="inline-flex">
          <button
            onClick={() => {
              setLastVisible(null);
              setCurrentPage((prev) => Math.max(prev - 1, 1));
            }}
            className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
            Prev
          </button>
          <button
            onClick={() => {
              if (hasMore) {
                setCurrentPage((prev) => prev + 1);
              }
            }}
            className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
              isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isNextDisabled}
          >
            Next
            <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserDirectory;
