import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

function SearchBar({ onSearch }) {
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchRef = useRef();

  useEffect(() => {
    debouncedSearchRef.current = debounce((value) => {
      onSearch(value);
    }, 1000);

    return () => {
      debouncedSearchRef.current.cancel();
    };
  }, [onSearch]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearchRef.current(value);
  };

  const handleClear = () => {
    setInputValue("");
    onSearch("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    debouncedSearchRef.current.flush();
    onSearch(inputValue);
  };

  return (
    <form onSubmit={handleSearch} className="mb-4 flex items-center relative">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <input
        id="search"
        type="text"
        placeholder="Search"
        className="flex-grow p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-400 pr-10"
        value={inputValue}
        onChange={handleChange}
        autoComplete="off"
        aria-label="Search"
        title="Search"
      />
      {inputValue && (
        <button
          type="button"
          onClick={handleClear}
          className="absolute right-12 p-2 text-gray-600"
          title="Clear"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      <button
        type="submit"
        className="p-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        title="Search"
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </form>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
