import React, { useState, useEffect } from "react";
import "./tailwind.css";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { db, doc, getDoc } from "./firebase";
import { updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";

function CompleteApplication() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [applicationComplete, setApplicationComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ssnUploadProgress, setSsnUploadProgress] = useState(0);
  const [ssnFileUploaded, setSsnFileUploaded] = useState(false);
  const [ssnFileRef, setSsnFileRef] = useState(null);
  const [itinUploadProgress, setItinUploadProgress] = useState(0);
  const [itinFileUploaded, setItinFileUploaded] = useState(false);
  const [itinFileRef, setItinFileRef] = useState(null);
  const [driversLicenseUploadProgress, setDriversLicenseUploadProgress] =
    useState(0);
  const [driversLicenseFileUploaded, setDriversLicenseFileUploaded] =
    useState(false);
  const [driversLicenseFileRef, setDriversLicenseFileRef] = useState(null);

  // State for our new fields
  const [bank, setBank] = useState("");
  const [routing, setRouting] = useState("");
  const [account, setAccount] = useState("");
  const [driversLicense, setDriversLicense] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [itin, setItin] = useState("");
  const [socialSecurity, setSocialSecurity] = useState("");
  const [open, setOpen] = useState(false);
  const [isOtherBank, setIsOtherBank] = useState(false);
  const [otherBankName, setOtherBankName] = useState("");

  const handleItinFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("File selected:", file);

    if (file.type !== "application/pdf") {
      console.error("File type is not PDF");
      window.alert("Only PDF files are allowed for ITIN documents.");
      return;
    }

    setItinUploadProgress(0);
    setItinFileUploaded(false);

    const storage = getStorage();
    const fileRef = ref(storage, `itin-documents/${uid}/${file.name}`);
    setItinFileRef(fileRef);
    console.log("Storage reference created:", fileRef);

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);
      console.log("Upload task started");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setItinUploadProgress(progress);
          console.log(`Upload progress: ${progress.toFixed(0)}%`);
        },
        (error) => {
          console.error("Error during upload:", error);
          setError(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const userDocRef = doc(db, "users", uid);
            updateDoc(userDocRef, { itinFileUrl: url });
            console.log("Uploaded ITIN document:", url);
            setItinFileUploaded(true);
          });
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
      setError(error.message);
    }
  };

  const removeItinFile = () => {
    if (itinFileRef) {
      deleteObject(itinFileRef)
        .then(() => {
          console.log("File removed");
          setItinFileRef(null);
        })
        .catch((error) => {
          console.error("Error removing file:", error);
        });
    }

    setItinUploadProgress(0);
    setItinFileUploaded(false);
  };

  const handleSsnFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("File selected:", file);

    // Validate file type
    if (file.type !== "application/pdf") {
      console.error("File type is not PDF");
      window.alert("Only PDF files are allowed for SSN documents.");
      return;
    }

    // Reset progress and uploaded state
    setSsnUploadProgress(0);
    setSsnFileUploaded(false);

    // Create a storage reference
    const storage = getStorage();
    const fileRef = ref(storage, `ssn-documents/${uid}/${file.name}`);
    setSsnFileRef(fileRef);
    console.log("Storage reference created:", fileRef);

    try {
      // Upload the file with progress tracking
      const uploadTask = uploadBytesResumable(fileRef, file);
      console.log("Upload task started");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Calculate and update upload progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setSsnUploadProgress(progress);
          console.log(`Upload progress: ${progress.toFixed(0)}%`);
        },
        (error) => {
          console.error("Error during upload:", error);
          setError(error.message);
        },
        () => {
          // Handle successful uploads
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const userDocRef = doc(db, "users", uid);
            updateDoc(userDocRef, { ssnFileUrl: url });
            console.log("Uploaded SSN document:", url);
            setSsnFileUploaded(true); // Confirm file upload
          });
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
      setError(error.message);
    }
  };

  const removeSsnFile = () => {
    if (ssnFileRef) {
      deleteObject(ssnFileRef)
        .then(() => {
          console.log("File removed");
          setSsnFileRef(null); // Reset file reference
        })
        .catch((error) => {
          console.error("Error removing file:", error);
        });
    }

    // Reset states
    setSsnUploadProgress(0);
    setSsnFileUploaded(false);
  };

  const handleDriversLicenseFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("Driver's License File selected:", file);

    // File type check (e.g., 'image/jpeg', 'image/png')
    if (!file.type.startsWith("image/")) {
      console.error("File type is not an image");
      window.alert("Only image files are allowed for the driver's license.");
      return;
    }

    setDriversLicenseUploadProgress(0);
    setDriversLicenseFileUploaded(false);

    const storage = getStorage();
    const fileRef = ref(storage, `drivers-license/${uid}/${file.name}`);
    setDriversLicenseFileRef(fileRef);
    console.log("Storage reference created:", fileRef);

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);
      console.log("Upload task started");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setDriversLicenseUploadProgress(progress);
          console.log(`Upload progress: ${progress.toFixed(0)}%`);
        },
        (error) => {
          console.error("Error during upload:", error);
          setError(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const userDocRef = doc(db, "users", uid);
            updateDoc(userDocRef, { driversLicenseFileUrl: url });
            console.log("Uploaded driver's license:", url);
            setDriversLicenseFileUploaded(true);
          });
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
      setError(error.message);
    }
  };

  const removeDriversLicenseFile = () => {
    if (driversLicenseFileRef) {
      deleteObject(driversLicenseFileRef)
        .then(() => {
          console.log("Driver's license file removed");
          setDriversLicenseFileRef(null);
        })
        .catch((error) => {
          console.error("Error removing file:", error);
        });
    }

    setDriversLicenseUploadProgress(0);
    setDriversLicenseFileUploaded(false);
  };

  async function handleCompleteFormSubmit() {
    // Construct the payload for identification data
    const identificationData = {};
    let documentUrl = "";
    let driversLicenseUrl = "";

    // Determine which identification data to use and get the document URL
    if (identificationType === "itin") {
      identificationData.itin = itin;
      documentUrl = itinFileUploaded ? await getDownloadURL(itinFileRef) : "";
    } else if (identificationType === "ssn") {
      identificationData.socialSecurity = socialSecurity;
      documentUrl = ssnFileUploaded ? await getDownloadURL(ssnFileRef) : "";
    }

    // Get the driver's license URL if uploaded
    driversLicenseUrl = driversLicenseFileUploaded
      ? await getDownloadURL(driversLicenseFileRef)
      : "";

    // Gather all the data for updating the user
    const userDataUpdate = {
      bank,
      routing,
      account, // Assuming you have a state variable for 'account'
      driversLicense,
      ...identificationData,
      documentUrl, // URL for ITIN/SSN document
      driversLicenseUrl, // URL for driver's license picture
      applicationComplete: true,
    };

    try {
      // Update the user's data in Firestore
      const userRef = doc(db, "users", uid);
      await updateDoc(userRef, userDataUpdate);

      // Open the modal
      setOpen(true);
    } catch (e) {
      // Handle errors
      setError(e.message);
      setOpen(true);
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setApplicationComplete(userData.applicationComplete);

          // Set identificationType if available in user data
          if (userData.identificationType) {
            setIdentificationType(userData.identificationType);
          }

          setIsLoading(false);
        } else {
          setError(`User data not found for UID: ${uid}`);
          setApplicationComplete(true);
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError(`An error occurred while fetching user data: ${err.message}`);
        setIsLoading(false);
      }
    };

    if (uid) {
      fetchUserData();
    }
  }, [uid]);

  // Redirect to login if the application is already complete
  useEffect(() => {
    if (applicationComplete) {
      navigate("/login");
    }
  }, [applicationComplete, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-8 bg-[#1F2937] min-h-screen text-white">
      <h2 className="text-2xl font-bold mb-6 audiowide">
        Complete Your Application
      </h2>
      <div className="w-full max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
        {/* Bank Information */}
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Banking Information
        </h3>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="bank"
            >
              Bank Name
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="bank"
              value={bank}
              onChange={(e) => {
                setBank(e.target.value);
                setIsOtherBank(e.target.value === "other");
              }}
            >
              <option value="Chase">Chase</option>
              <option value="Bank of America">Bank of America</option>
              <option value="Citi Bank">Citi Bank</option>
              <option value="Wells Fargo">Wells Fargo</option>
              <option value="U.S. Bank">U.S. Bank</option>
              <option value="PNC Bank">PNC Bank</option>
              <option value="Truist Bank">Truist Bank</option>
              <option value="Goldman Sachs">Goldman Sachs</option>
              <option value="Capital One">Capital One</option>
              <option value="TD Bank">TD Bank</option>
              <option value="other">Other</option>
            </select>
            {isOtherBank && (
              <div className="w-full px-3 mt-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="otherBank"
                >
                  Please specify
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="otherBank"
                  type="text"
                  value={otherBankName}
                  onChange={(e) => setOtherBankName(e.target.value)}
                  placeholder="Type your bank name"
                />
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="routing"
            >
              Routing Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="routing"
              type="tel"
              pattern="\d{9}"
              inputMode="numeric"
              maxLength="9"
              value={routing}
              onChange={(e) =>
                setRouting(e.target.value.replace(/\D/g, "").slice(0, 9))
              }
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="account"
            >
              Account Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="account"
              type="tel"
              pattern="\d{8,12}"
              inputMode="numeric"
              maxLength="12"
              value={account}
              onChange={(e) =>
                setAccount(e.target.value.replace(/\D/g, "").slice(0, 12))
              }
            />
          </div>
        </div>
        <hr className="my-6 border-t border-gray-300" />

        {/* SSN/ITIN & Driver's License */}
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937]">
          Personal Information
        </h3>
        <div className="flex flex-wrap -mx-3 mb-6">
          {identificationType === "itin" ? (
            // ITIN input field
            <div className="w-full md:w-1/2 px-3">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="itin"
                >
                  ITIN
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="itin"
                  type="password"
                  value={itin}
                  onChange={(e) => setItin(e.target.value.replace(/\D/g, ""))}
                />
              </div>
              {/* File upload field for 1099 form */}
              <div className="w-full md:w-1/2 px-3 mt-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="itinFile"
                >
                  Upload Your W-9 Form
                </label>
                <p className="text-xs text-gray-500 mt-2">
                  Please upload your filled-out W-9 form here. If you do not
                  have one, you can download a copy of the form from the{" "}
                  <a
                    href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    IRS website
                  </a>
                  .
                </p>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="itinFile"
                  type="file"
                  onChange={handleItinFileChange}
                />
                {itinUploadProgress > 0 && itinUploadProgress < 100 && (
                  <div className="w-full bg-gray-200 rounded-full mt-2">
                    <div
                      className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${itinUploadProgress}%` }}
                    >
                      {itinUploadProgress.toFixed(0)}%
                    </div>
                  </div>
                )}
                {itinFileUploaded && (
                  <div className="text-green-600 mt-2">
                    File uploaded successfully.
                    <button
                      className="text-blue-600 ml-2"
                      onClick={removeItinFile}
                    >
                      Remove File
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : identificationType === "ssn" ? (
            // SSN Section
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="socialSecurity"
              >
                Social Security Number
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="socialSecurity"
                type="password"
                value={socialSecurity}
                onChange={(e) =>
                  setSocialSecurity(e.target.value.replace(/\D/g, ""))
                }
              />
              <div className="w-full md:w-1/2 px-3 mt-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="ssnFile"
                >
                  Upload W4 Form
                </label>
                <p className="text-xs text-gray-500 mt-2">
                  Please upload your filled-out W-4 form here. If you do not
                  have one, you can download a copy of the form from the{" "}
                  <a
                    href="https://www.irs.gov/pub/irs-pdf/fw4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    IRS website
                  </a>
                  .
                </p>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="ssnFile"
                  type="file"
                  onChange={handleSsnFileChange}
                />
                {ssnUploadProgress > 0 && ssnUploadProgress < 100 && (
                  <div className="w-full bg-gray-200 rounded-full mt-2">
                    <div
                      className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${ssnUploadProgress}%` }}
                    >
                      {ssnUploadProgress.toFixed(0)}%
                    </div>
                  </div>
                )}
                {ssnFileUploaded && (
                  <div className="text-green-600 mt-2">
                    File uploaded successfully.
                    <button
                      className="text-blue-600 ml-2"
                      onClick={removeSsnFile}
                    >
                      Remove File
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="driversLicense"
            >
              Photo ID
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="driversLicense"
              type="text"
              value={driversLicense}
              onChange={(e) => setDriversLicense(e.target.value)}
            />
            <div className="mt-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="driversLicenseFile"
              >
                Upload Photo ID Picture
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="driversLicenseFile"
                type="file"
                onChange={handleDriversLicenseFileChange}
              />
              <p className="text-xs text-gray-500 mt-2">
                Please upload a clear picture of your photo ID.
              </p>
              {driversLicenseUploadProgress > 0 &&
                driversLicenseUploadProgress < 100 && (
                  <div className="w-full bg-gray-200 rounded-full mt-2">
                    <div
                      className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${driversLicenseUploadProgress}%` }}
                    >
                      {driversLicenseUploadProgress.toFixed(0)}%
                    </div>
                  </div>
                )}
              {driversLicenseFileUploaded && (
                <div className="text-green-600 mt-2">
                  File uploaded successfully.
                  <button
                    className="text-blue-600 ml-2"
                    onClick={removeDriversLicenseFile}
                  >
                    Remove File
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div class="flex">
          <button
            type="submit"
            class="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={handleCompleteFormSubmit}
          >
            Submit
          </button>
        </div>

        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => setOpen(false)}
          >
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              {/* Actual modal body */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg dark:bg-gray-700 sm:max-w-lg sm:w-full sm:p-6">
                  <div className="bg-white dark:bg-gray-700 px-4 pt-5 pb-4 border-b rounded-t dark:border-gray-600 sm:p-6 sm:pb-4">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                        {error
                          ? "Error Submitting Application"
                          : "Application Submitted"}
                      </h3>
                    </div>
                    <div className="mt-3">
                      {error ? (
                        <>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="red"
                            size="2x"
                            className="sm:hidden mx-auto"
                          />
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="red"
                            size="lg"
                            className="hidden sm:block mx-auto"
                          />
                          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Uh-oh! We encountered an issue: {error}. This might
                            be due to a small glitch or a network hiccup. Please
                            try again in a few moments, or reach out to our
                            support team if the issue persists. We're here to
                            ensure everything runs smoothly for you.
                          </p>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                            className="sm:hidden mx-auto"
                          />
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="lg"
                            className="hidden sm:block mx-auto"
                          />
                          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Great news – your application has been updated
                            successfully! Your manager will be in touch with you
                            shortly to discuss the next steps. Thank you for
                            your patience and cooperation.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      type="button"
                      className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => {
                        setOpen(false);
                        if (!error) {
                          // Redirect to the login screen
                          window.location.href = "/login";
                        }
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

export default CompleteApplication;
