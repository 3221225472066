import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

const useRequireNCASignature = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkNCASignature = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && !userDoc.data().hasSignedNonCompete) {
          navigate("/non-compete-agreement");
        }
      }
    };

    checkNCASignature();
  }, [navigate]);
};

export default useRequireNCASignature;
