import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

function WarningModal({ isOpen, onClose, onSave, initialDate, employeeId }) {
  const [existingWarningTexts, setExistingWarningTexts] = useState("");
  const [newWarningText, setNewWarningText] = useState("");
  const [warningDate, setWarningDate] = useState(initialDate);

  useEffect(() => {
    // When the modal opens, fetch existing warnings
    if (isOpen && employeeId) {
      const fetchWarnings = async () => {
        const employeeDocRef = doc(db, "users", employeeId);
        const docSnapshot = await getDoc(employeeDocRef);
        if (docSnapshot.exists()) {
          const warnings = docSnapshot.data().warnings || [];
          const combinedWarningTexts = warnings
            .map((warning) => `${warning.date}: ${warning.text}`)
            .join("\n\n");
          setExistingWarningTexts(combinedWarningTexts);
        } else {
          console.error("No such employee document!");
          onClose();
        }
      };
      fetchWarnings();
    }
  }, [isOpen, employeeId, onClose]);

  // Reset the new warning input when closing the modal
  useEffect(() => {
    if (!isOpen) {
      setNewWarningText("");
    }
  }, [isOpen]);

  const handleNewWarningTextChange = (e) => {
    setNewWarningText(e.target.value);
  };

  return isOpen ? (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Add Warning
          </h3>
          <div className="mt-2 px-7 py-3">
            {existingWarningTexts && (
              <>
                <p>Existing Warnings:</p>
                <textarea
                  className="textarea textarea-bordered w-full"
                  value={existingWarningTexts}
                  readOnly
                  rows="6"
                />
              </>
            )}
            <p>Add New Warning:</p>
            <textarea
              className="textarea textarea-bordered w-full"
              placeholder="New warning details"
              value={newWarningText}
              onChange={handleNewWarningTextChange}
              rows="2"
            />
            <input
              type="date"
              className="input input-bordered w-full mt-4"
              value={warningDate}
              onChange={(e) => setWarningDate(e.target.value)}
            />
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
              onClick={() => onSave(warningDate, newWarningText)}
            >
              Save Warning
            </button>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="cancel-btn"
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default WarningModal;
