import React from "react";
import { db } from "./firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";

const Salary = () => {
  const formatCurrency = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const fetchEmployees = async () => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("status", "==", "Active"),
      where("salary", "!=", null)
    );
    const querySnapshot = await getDocs(q);
    const employeeData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const salaryNumber = parseFloat(data.salary) || 0;
      return {
        id: doc.id,
        firstName: data.firstName || "Unknown",
        lastName: data.lastName || "Unknown",
        location: data.workLocation || "Unknown",
        payMethod: data.payMethod || "Unknown",
        salary: salaryNumber,
        weeklySalary: salaryNumber / 52,
      };
    });

    return employeeData
      .filter((employee) => employee.salary > 0)
      .sort((a, b) => a.firstName.localeCompare(b.firstName));
  };

  const {
    data: employees,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employees"],
    queryFn: fetchEmployees,
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsHeader = [
      [
        "First Name",
        "Last Name",
        "Work Location",
        "Pay Method",
        "Annual Salary",
        "Weekly Salary",
      ],
    ];

    const wsData = employees.map((employee) => [
      employee.firstName,
      employee.lastName,
      employee.location,
      employee.payMethod,
      formatCurrency(employee.salary),
      formatCurrency(employee.weeklySalary),
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Employee Salaries");

    const filename = `employee_salaries_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employee Salaries</h1>
        <button
          onClick={exportToExcel}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
        </button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : employees && employees.length === 0 ? (
        <p>No employees found with salary information.</p>
      ) : (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 rounded-tl-lg">First Name</th>
              <th className="px-4 py-2">Last Name</th>
              <th className="px-4 py-2">Location</th>
              <th className="px-4 py-2">Pay Method</th>
              <th className="px-4 py-2">Annual Salary</th>
              <th className="px-4 py-2 rounded-tr-lg">Weekly Salary</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td className="border px-4 py-2">{employee.firstName}</td>
                <td className="border px-4 py-2">{employee.lastName}</td>
                <td className="border px-4 py-2">{employee.location}</td>
                <td className="border px-4 py-2">{employee.payMethod}</td>
                <td className="border px-4 py-2">
                  {formatCurrency(employee.salary)}
                </td>
                <td className="border px-4 py-2">
                  {formatCurrency(employee.weeklySalary)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Salary;
