import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "uniquepunchcard-ab19f.firebaseapp.com",
  projectId: "uniquepunchcard-ab19f",
  storageBucket: "uniquepunchcard-ab19f.appspot.com",
  messagingSenderId: "1032983132560",
  appId: "1:1032983132560:web:dcc28d010da011c2f0db15",
  measurementId: "G-NV140R7YXD",
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Functions
const functions = getFunctions(app);

// Initialize App Check after Firebase App initialization
// eslint-disable-next-line no-unused-vars
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore(app);
const auth = getAuth(app);

// Export the necessary modules
export {
  auth,
  db,
  doc,
  getDoc,
  functions,
  serverTimestamp,
  createUserWithEmailAndPassword,
};
