import { useState } from "react";
import mailcheck from "mailcheck";

const useEmailValidation = () => {
  const [emailError, setEmailError] = useState(null);
  const [emailSuggestion, setEmailSuggestion] = useState(null);

  const validateEmail = (email, setField) => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regexPattern.test(email)) {
      setEmailError(null);
      mailcheck.run({
        email: email,
        suggested: (suggestion) => {
          setEmailSuggestion(suggestion.full);
        },
        empty: () => {
          setEmailSuggestion(null);
        },
      });
    } else {
      setEmailError("Please enter a valid email address.");
      setEmailSuggestion(null);
    }
  };

  return { emailError, emailSuggestion, validateEmail };
};

export default useEmailValidation;
