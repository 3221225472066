import { useState } from "react";
import { db } from "./firebase";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "./LoadingSpinner";

const fetchReportingUsers = async (employeeId) => {
  const usersQuery = query(
    collection(db, "users"),
    where("status", "==", "Active"),
    where("reportsTo", "array-contains", employeeId)
  );
  const querySnapshot = await getDocs(usersQuery);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const fetchActiveUsers = async () => {
  const usersQuery = query(
    collection(db, "users"),
    where("status", "==", "Active")
  );
  const querySnapshot = await getDocs(usersQuery);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const ReportingUsers = ({ employeeId }) => {
  const [reportingUsersSearchTerm, setReportingUsersSearchTerm] = useState("");
  const [addUsersSearchTerm, setAddUsersSearchTerm] = useState("");
  const [reportingUsersPage, setReportingUsersPage] = useState(1);
  const [activeUsersPage, setActiveUsersPage] = useState(1);
  const itemsPerPage = 10;
  const queryClient = useQueryClient();

  const {
    data: reportingUsers,
    isLoading: isLoadingReportingUsers,
    error: errorReportingUsers,
  } = useQuery({
    queryKey: ["reportingUsers", employeeId],
    queryFn: () => fetchReportingUsers(employeeId),
    enabled: !!employeeId,
    onSuccess: (data) => {
      console.log("Successfully fetched reporting users:", data);
    },
    onError: (err) => {
      console.error("Error fetching reporting users:", err);
    },
  });

  const {
    data: activeUsers,
    isLoading: isLoadingActiveUsers,
    error: errorActiveUsers,
  } = useQuery({
    queryKey: ["activeUsers"],
    queryFn: fetchActiveUsers,
  });

  const removeUserMutation = useMutation({
    mutationFn: async (userId) => {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, { reportsTo: arrayRemove(employeeId) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["reportingUsers", employeeId]);
    },
    onError: (err) => {
      console.error("Error removing user:", err);
    },
  });

  const addUserMutation = useMutation({
    mutationFn: async (userId) => {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, { reportsTo: arrayUnion(employeeId) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["reportingUsers", employeeId]);
    },
    onError: (err) => {
      console.error("Error adding user:", err);
    },
  });

  const handleRemoveUser = (userId) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      removeUserMutation.mutate(userId);
    }
  };

  const handleAddUser = (userId) => {
    addUserMutation.mutate(userId);
  };

  const filteredReportingUsers = reportingUsers
    ? reportingUsers
        .filter(
          (user) =>
            user.firstName
              ?.toLowerCase()
              .includes(reportingUsersSearchTerm.toLowerCase()) ||
            user.lastName
              ?.toLowerCase()
              .includes(reportingUsersSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
    : [];

  const filteredActiveUsers = activeUsers
    ? activeUsers
        .filter(
          (user) =>
            user.id !== employeeId &&
            (user.firstName
              ?.toLowerCase()
              .includes(addUsersSearchTerm.toLowerCase()) ||
              user.lastName
                ?.toLowerCase()
                .includes(addUsersSearchTerm.toLowerCase()) ||
              user.workLocation
                ?.toLowerCase()
                .includes(addUsersSearchTerm.toLowerCase())) &&
            !user.reportsTo?.includes(employeeId)
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
    : [];

  const paginatedReportingUsers = filteredReportingUsers.slice(
    (reportingUsersPage - 1) * itemsPerPage,
    reportingUsersPage * itemsPerPage
  );

  const paginatedActiveUsers = filteredActiveUsers.slice(
    (activeUsersPage - 1) * itemsPerPage,
    activeUsersPage * itemsPerPage
  );

  const totalPagesReportingUsers = Math.ceil(
    filteredReportingUsers.length / itemsPerPage
  );
  const totalPagesActiveUsers = Math.ceil(
    filteredActiveUsers.length / itemsPerPage
  );

  if (
    isLoadingReportingUsers ||
    isLoadingActiveUsers ||
    removeUserMutation.isLoading ||
    addUserMutation.isLoading
  ) {
    return <LoadingSpinner />;
  }

  if (errorReportingUsers) {
    return <p>Error loading reporting users: {errorReportingUsers.message}</p>;
  }

  if (errorActiveUsers) {
    return <p>Error loading active users: {errorActiveUsers.message}</p>;
  }

  return (
    <div className="flex flex-col md:flex-row gap-4 px-4 mb-4">
      <div className="w-full md:w-1/2">
        <h3 className="font-bold mb-4">Reporting Users</h3>
        <input
          type="text"
          placeholder="Search reporting users..."
          value={reportingUsersSearchTerm}
          onChange={(e) => setReportingUsersSearchTerm(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        />
        {paginatedReportingUsers.length > 0 ? (
          <ul>
            {paginatedReportingUsers.map((user) => (
              <li
                key={user.id}
                className="flex justify-between items-center mb-2"
              >
                <span>
                  {user.firstName} {user.lastName}
                </span>
                <button
                  onClick={() => handleRemoveUser(user.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700 transition duration-300"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No users found.</p>
        )}
        <div className="flex justify-between items-center mt-4">
          <button
            disabled={reportingUsersPage === 1}
            onClick={() => setReportingUsersPage((prev) => prev - 1)}
            className={`${
              reportingUsersPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white px-2 py-1 rounded transition duration-300`}
          >
            Previous
          </button>
          <span>
            Page {reportingUsersPage} of {totalPagesReportingUsers}
          </span>
          <button
            disabled={reportingUsersPage === totalPagesReportingUsers}
            onClick={() => setReportingUsersPage((prev) => prev + 1)}
            className={`${
              reportingUsersPage === totalPagesReportingUsers
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white px-2 py-1 rounded transition duration-300`}
          >
            Next
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <h3 className="font-bold mb-4">Add Users to Report</h3>
        <input
          type="text"
          placeholder="Search users to add by name or location..."
          value={addUsersSearchTerm}
          onChange={(e) => setAddUsersSearchTerm(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        />
        {paginatedActiveUsers.length > 0 ? (
          <ul>
            {paginatedActiveUsers.map((user) => (
              <li
                key={user.id}
                className="flex justify-between items-center mb-2"
              >
                <span>
                  {user.firstName} {user.lastName} - {user.workLocation}
                </span>
                <button
                  onClick={() => handleAddUser(user.id)}
                  className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-700 transition duration-300"
                >
                  Add
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No users found to add.</p>
        )}
        <div className="flex justify-between items-center mt-4">
          <button
            disabled={activeUsersPage === 1}
            onClick={() => setActiveUsersPage((prev) => prev - 1)}
            className={`${
              activeUsersPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white px-2 py-1 rounded transition duration-300`}
          >
            Previous
          </button>
          <span>
            Page {activeUsersPage} of {totalPagesActiveUsers}
          </span>
          <button
            disabled={activeUsersPage === totalPagesActiveUsers}
            onClick={() => setActiveUsersPage((prev) => prev + 1)}
            className={`${
              activeUsersPage === totalPagesActiveUsers
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white px-2 py-1 rounded transition duration-300`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportingUsers;
