import React from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "./firebase";
import LoadingSpinner from "./LoadingSpinner"; // Import the LoadingSpinner component

async function fetchRejectedApplications() {
  const applicationsRef = collection(db, "rejected_applicants");
  const applicationsSnapshot = await getDocs(applicationsRef);

  return applicationsSnapshot.docs.map((doc) => {
    const data = doc.data();

    // Convert Firestore timestamp to JavaScript Date
    if (data.timestamp && typeof data.timestamp.toDate === "function") {
      data.timestamp = data.timestamp.toDate();
    }

    return { ...data, id: doc.id };
  });
}

// Define or import parseCustomDate function
function parseCustomDate(timestamp) {
  return new Date(timestamp);
}

function Rejected() {
  const queryClient = useQueryClient();
  const { data: applications = [], error, isLoading } = useQuery({
    queryKey: ['rejectedApplications'],
    queryFn: fetchRejectedApplications
  });

  async function deleteApplication(applicationId) {
    const applicationRef = doc(db, "rejected_applicants", applicationId);

    try {
      await deleteDoc(applicationRef);
      // Invalidate and refetch
      queryClient.invalidateQueries(['rejectedApplications']);
    } catch (error) {
      console.error("Error deleting application: ", error);
    }
  }

  if (isLoading) return <LoadingSpinner />; // Use LoadingSpinner component
  if (error) return <div>Error loading applications</div>;

  return (
    <div className="bg-[#1F2937] p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-white uppercase tracking-wider">
        Rejected Applicants
      </h2>
      <table className="w-full bg-white rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Name
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Submitted On
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Notes
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => {
            let dateObject = parseCustomDate(application.timestamp);
            let formattedDate =
              dateObject instanceof Date && !isNaN(dateObject.getTime())
                ? dateObject.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Invalid Date";

            return (
              <tr
                key={application.id}
                className="hover:bg-gray-100 transition-all"
              >
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  <div className="font-medium text-lg">
                    {application.firstName} {application.middleName}{" "}
                    {application.lastName}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  {formattedDate}
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  <div className="font-small text-lg">{application.notes}</div>
                </td>
                <td className="px-5 py-5 border-b border-gray-300 text-sm">
                  <button
                    onClick={() => deleteApplication(application.id)}
                    className="px-4 py-2 bg-red-500 font-bold text-white rounded hover:bg-red-700"
                    title="Delete Application"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Rejected;
