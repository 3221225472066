import React from "react";
import "./tailwind.css";

function InspectionForm({ isGoogleMapsLoaded }) {
  return (
    <div className="p-8 bg-[#1F2937] min-h-screen text-white">
      <h2 className="text-2xl font-bold mb-6 audiowide">Inspection Form </h2>
      <div className="w-full max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 mt-4 text-[#1F2937] text-center">
          Sixt LaGuardia Shuttle Pre-Trip Checklist
        </h3>
        <hr className="my-6 border-t border-gray-300" />
        <div className="flex flex-wrap -mx-3 mb-6"></div>
        <div className="flex flex-wrap -mx-3 mb-6"></div>
        <hr className="my-6 border-t border-gray-300" />
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4"></div>
        </div>
      </div>
    </div>
  );
}

export default InspectionForm;
