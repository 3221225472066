import React, { useState, useEffect } from "react";
import { auth, db } from "./firebase";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import UserDirectory from "./UserDirectory";
import useRequireNCASignature from "./useRequireNCASignature";
import ApplicationReview from "./ApplicationReview";
import Rejected from "./Rejected";
import Locations from "./Locations";
import Reports from "./Reports";
import Salary from "./Salary";
import Invoices from "./Invoices";
import Timesheets from "./Timesheet";
import Transactions from "./Transactions";
import BirthdayFlyout from "./BirthdayFlyout";
import { useUser } from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faUsers,
  faInbox,
  faTimesCircle,
  faUserClock,
  faLocationDot,
  faBarsStaggered,
  faRightFromBracket,
  faFileInvoiceDollar,
  faFileInvoice,
  faCakeCandles,
  faClock,
  faMoneyCheckDollar,
  faRightLeft
} from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";

function Dashboard({ userRole, isGoogleMapsLoaded }) {
  const [userFirstName, setUserFirstName] = useState("");
  const [greeting, setGreeting] = useState("");
  const { setUserName } = useUser();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  useRequireNCASignature();

  useEffect(() => {
    const fetchAndSetUserDetails = async () => {
      if (!auth.currentUser) {
        navigate("/login");
        return;
      }

      try {
        const currentUserUID = auth.currentUser.uid;
        const userRef = doc(db, "users", currentUserUID);
        const docSnapshot = await getDoc(userRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const firstName = data.firstName || "";
          const middleName = data.middleName ? `${data.middleName} ` : "";
          const lastName = data.lastName || "";

          setUserFirstName(firstName);
          setUserName(`${firstName} ${middleName}${lastName}`.trim());
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchAndSetUserDetails();
  }, [navigate, setUserName]);

  const fetchCounts = async (collectionName, whereCondition = null) => {
    const ref = collection(db, collectionName);
    const q = whereCondition ? query(ref, whereCondition) : ref;
    const snapshot = await getDocs(q);
    return snapshot.size;
  };

  const { data: applicationCount } = useQuery({
    queryKey: ["applicationCount"],
    queryFn: () => fetchCounts("applications"),
    enabled:
      userRole &&
      ["owner", "manager", "admin", "director", "supervisor"].includes(
        userRole
      ),
  });

  const { data: activeUserCount } = useQuery({
    queryKey: ["activeUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "Active")),
    enabled:
      userRole &&
      ["owner", "manager", "admin", "director", "supervisor"].includes(
        userRole
      ),
  });

  const { data: inactiveUserCount } = useQuery({
    queryKey: ["inactiveUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "inactive")),
    enabled:
      userRole &&
      ["owner", "manager", "admin", "director", "supervisor"].includes(
        userRole
      ),
  });

  const { data: terminatedUserCount } = useQuery({
    queryKey: ["terminatedUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "terminated")),
    enabled:
      userRole &&
      ["owner", "manager", "admin", "director", "supervisor"].includes(
        userRole
      ),
  });

  const { data: locationsCount } = useQuery({
    queryKey: ["locationsCount"],
    queryFn: () => fetchCounts("locations"),
    enabled: userRole && ["owner", "manager", "admin"].includes(userRole),
  });

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  return (
    <div className="flex min-h-screen bg-[#1F2937]">
      <button
        className="sm:hidden z-50"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        style={{ position: "absolute", top: 10, left: 10 }}
      >
        <FontAwesomeIcon icon={faBarsStaggered} className="text-white" />
      </button>
      {/* Side Menu */}
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pt-12 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <h1
            className="text-2xl font-bold text-black dark:text-white audiowide cursor-pointer"
            onClick={() => {
              window.location.reload();
            }}
          >
            UNIQUE HUB
          </h1>
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/dashboard"
                className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  />
                  <span className="ml-3">Active</span>
                </div>
                <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  {activeUserCount ?? 0}
                </span>
              </Link>
            </li>
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin" ||
              userRole === "director" ||
              userRole === "supervisor") && (
              <li>
                <Link
                  to="/dashboard/inactive"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faUserClock}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Inactive</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    {inactiveUserCount ?? 0}
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin" ||
              userRole === "director" ||
              userRole === "supervisor") && (
              <li>
                <Link
                  to="/dashboard/terminated"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Terminated</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    {terminatedUserCount ?? 0}
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin" ||
              userRole === "director") && (
              <li>
                <Link
                  to="/dashboard/review"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faInbox}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Applications</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    {applicationCount ?? 0}
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin" ||
              userRole === "director") && (
              <li>
                <Link
                  to="/dashboard/rejected"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faBan}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Rejected</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/locations"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Locations</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    {locationsCount ?? 0}
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/reports"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Reports</span>
                  </div>
                </Link>
              </li>
            )}
            {userRole === "owner" && (
              <li>
                <Link
                  to="/dashboard/salary"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Salary</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/invoices"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faFileInvoice}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Invoices</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/timesheets"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Timesheets</span>
                  </div>
                </Link>
              </li>
            )}
            {(
              <li>
                <Link
                  to="/dashboard/transactions"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faRightLeft}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Transactions</span>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </aside>
      <div className="flex-grow p-4 sm:ml-64">
        <div className="flex flex-col h-full bg-[#1F2937]">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-lg font-bold text-white uppercase tracking-wider ">
              Dashboard
            </h1>
            <div className="flex items-center">
              <div className="flex items-center">
                <span className="mr-4 text-white font-bold">
                  {greeting}, {userFirstName}
                </span>
                <div className="relative mr-4">
                  <button
                    onClick={() => setIsFlyoutOpen(!isFlyoutOpen)}
                    className="p-2 bg-green-500 hover:bg-green-700 text-white font-bold rounded transition duration-150 ease-in-out"
                  >
                    <FontAwesomeIcon icon={faCakeCandles} /> Birthdays
                  </button>
                  {isFlyoutOpen && (
                    <BirthdayFlyout
                      closeFlyout={() => setIsFlyoutOpen(false)}
                    />
                  )}
                </div>
              </div>
              <button
                onClick={() => auth.signOut()}
                className="p-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded transition duration-150 ease-in-out"
              >
                <FontAwesomeIcon icon={faRightFromBracket} /> Logout
              </button>
            </div>
          </div>
          <div className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <UserDirectory
                    key="active"
                    userStatus="Active"
                    userRole={userRole}
                  />
                }
              />
              <Route
                path="inactive"
                element={
                  <UserDirectory
                    key="inactive"
                    userStatus="inactive"
                    userRole={userRole}
                  />
                }
              />
              <Route
                path="terminated"
                element={
                  <UserDirectory
                    key="terminated"
                    userStatus="terminated"
                    userRole={userRole}
                  />
                }
              />
              <Route path="review" element={<ApplicationReview />} />
              <Route path="rejected" element={<Rejected />} />
              <Route
                path="locations"
                element={<Locations isGoogleMapsLoaded={isGoogleMapsLoaded} />}
              />
              <Route path="reports" element={<Reports />} />
              <Route path="salary" element={<Salary />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="timesheets" element={<Timesheets />} />
              <Route path="transactions" element={<Transactions />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
